import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Box,
  Input,
  Table,
  TableCaption,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Button,
} from "@chakra-ui/react";
import toast from 'react-hot-toast';
import * as XLSX from 'xlsx'

const QuickBillData = () => {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [printData,setPrintData]=useState([])

  const customerId = localStorage.getItem('customerId');

  const fetchData = async () => {
    const response = await axios.get(`https://api.rcb.tdrsoftware.in/api/getQuickBillData/${customerId}`);
    setData(response.data.quickBill);
    setFilteredData(response.data.quickBill);
  };
  const fetchData3=async()=>{
    const response= await axios.get(`https://api.rcb.tdrsoftware.in/api/getPrintData/${customerId}`)
    setPrintData(response.data)
    const rooms = response.data;
    const updatedRooms = await Promise.all(
      rooms.map(async (room) => {
        const imageResponse = await axios.get('https://api.rcb.tdrsoftware.in/image', {
          params: { customerId, key: room.image }
        });
        room.imageUrl = imageResponse.data;
        return room;
      })
    );
    console.log(updatedRooms, 'updatedRooms');
    setPrintData(updatedRooms);
}
  const handlePayment = async (id) => {
    try {
      const response = await axios.put(`https://api.rcb.tdrsoftware.in/api/updatePayment/${customerId}/${id}`);
      alert(response.data.message);
      window.location.reload('/quickBillData');
    } catch (error) {
      toast.error(error);
    }
  };
  useEffect(() => {
    fetchData();
    fetchData3()
  }, [customerId]);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    filterData(event.target.value, startDate, endDate);
  };

  const handleStartDateChange = (event) => {
    setStartDate(event.target.value);
    filterData(searchTerm, event.target.value, endDate);
  };

  const handleEndDateChange = (event) => {
    setEndDate(event.target.value);
    filterData(searchTerm, startDate, event.target.value);
  };

  const filterData = (term, start, end) => {
    let filtered = data.filter(order => {
      const orderDate = new Date(order.createdAt).getTime();
      const startFilter = start ? new Date(start).getTime() : null;
      const endFilter = end ? new Date(end).getTime() : null;

      if (!term && !startFilter && !endFilter) {
        return true; // No filters applied, return all data
      }

      const matchesTerm = term ? (
        order.name.toLowerCase().includes(term.toLowerCase()) ||
        order.phoneNo.includes(term) // Include phone number in search
      ) : true; // If no term, treat it as true

      const withinDateRange = (!startFilter || orderDate >= startFilter) &&
        (!endFilter || orderDate <= endFilter);

      return (matchesTerm || !term) && withinDateRange; // If no term, skip term filtering, only use date range
    });

    setFilteredData(filtered);
};

  const handlePrint = (order) => {
    const foodItemsContent = order.foodItem.map(item => `
      <tr>
        <td>${item.name}</td>
        <td>${item.quantity}</td>
        <td>${item.price}</td>
      </tr>
    `).join('');
  
    const wineItemsContent = order.wineItem.map(item => `
      <tr>
        <td>${item.name}</td>
        <td>${item.quantity}</td>
        <td>${item.price}</td>
      </tr>
    `).join('');
  
    const content = `
      <html>
        <head>
          <style>
            body { font-family: Arial, sans-serif; width: 3in; }
            table { width: 100%; border-collapse: collapse; }
            th, td { border: 1px solid #dddddd; text-align: left; padding: 4px; font-size: 12px; }
            th { background-color: #f2f2f2; }
            h2, h4, h5 { text-align: center; margin: 4px 0; font-size: 14px; }
          </style>
        </head>
        <body>
          <h2>Quick Bill</h2>
          ${printData.map(item => `
            <div style="text-align:center">
              <img src="${item.imageUrl}" style="width: 50px; height: 50px; border-radius: 12px;" />
              <h4>${item.name}</h4>
              <h5>${item.adress}</h5>
              <h5>Phone: ${item.phoneNo}</h5>
              <h5>Website: ${item.website}</h5>
              <h5>Email: ${item.email}</h5>
              <h5>GST No: ${item.gstNo}</h5>
            </div>
          `).join('')}
          
          <table>
            <thead>
              <tr>
                <th>Item Name</th>
                <th>Quantity</th>
                <th>Price</th>
              </tr>
            </thead>
            <tbody>
              ${foodItemsContent}
              
            </tbody>
          </table>
             <table style="margin-top: 10px;">
            <thead>
              <tr>
                <th>Item Name</th>
                <th>Quantity</th>
                <th>Price</th>
              </tr>
            </thead>
            <tbody>
              ${wineItemsContent}
              
            </tbody>
          </table>
  
          <table style="margin-top: 10px;">
            <thead>
              <tr>
                <th>Wine Amount</th>
                <th>Food Amount</th>
                <th>CGST(2.5%)</th>
                <th>SGST(2.5%)</th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>${order.wineItem.reduce((acc, d) => acc + (d.price * d.quantity), 0)}</td>
                <td>${order.foodItem.reduce((acc, d) => acc + (d.price * d.quantity), 0)}</td>
                <td>${order.foodItem.reduce((acc, d) => acc + (d.price * d.quantity) * 0.025, 0)}</td>
                <td>${order.foodItem.reduce((acc, d) => acc + (d.price * d.quantity) * 0.025, 0)}</td>
                <td>${order.totalBilling}</td>
              </tr>
            </tbody>
          </table>
          <script>
            window.onload = () => window.print();
          </script>
        </body>
      </html>
    `;
  
    const printWindow = window.open('', '', 'height=600,width=800');
    printWindow.document.open();
    printWindow.document.write(content);
    printWindow.document.close();
  };
  
  const exportToExcel = () => {
    // Prepare data for export by flattening foodItem and wineItem arrays
    const cleanedData = filteredData.map((order) => {
      // Concatenate all food items with NameXQuantity format
      const foodItemsCombined = order.foodItem
        .map(foodItem => `${foodItem.name}X${foodItem.quantity}`)
        .join(', '); // Join all items into a single string
  
      // Concatenate all wine items with NameXQuantity format
      const wineItemsCombined = order.wineItem
        .map(wineItem => `${wineItem.name}X${wineItem.quantity}`)
        .join(', ');
  
      return {
        Name: order.name,
        PhoneNo: order.phoneNo,
        FoodItems: foodItemsCombined, // Combined food items in one cell
        WineItems: wineItemsCombined, // Combined wine items in one cell
        TotalBill: order.totalBilling,
        TableNo: order.tableNo,
        
        CreatedAt: new Date(order.createdAt).toLocaleString('en-GB')
      };
    });
  
    // Create worksheet
    const worksheet = XLSX.utils.json_to_sheet(cleanedData);
  
    // Add borders and other styling (if needed)
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'QuickBillData');
  
    // Write the workbook to an Excel file
    XLSX.writeFile(workbook, 'QuickBillData.xlsx');
  };
  

  

  return (
    <Box>
      <Box mb={4} display="flex" justifyContent="space-between">
        <Input
          w='40%'
          type="text"
          placeholder="Search by Name or Phone"
          value={searchTerm}
          onChange={handleSearchChange}
        />
        <Input
          w='20%'
          type="date"
          placeholder="Start Date"
          value={startDate}
          onChange={handleStartDateChange}
          mr={2}
        />
        <Input
          w='20%'
          type="date"
          placeholder="End Date"
          value={endDate}
          onChange={handleEndDateChange}
          mr={2}
        />
      </Box>
      <Button colorScheme="blue" onClick={exportToExcel} p={2} mb={5}>
       Export to Excel File
      </Button>
      <TableContainer width='full'>
        <Table size='sm' variant='striped' colorScheme='green'>
          <TableCaption>Quick Bill Data</TableCaption>
          <Thead>
            <Tr>
              <Th>Food Item</Th>
              <Th>Wine Item</Th>
              <Th>Name</Th>
              <Th>Phone No</Th>
              <Th>Bill No</Th>
              <Th>Table No</Th>
              <Th>Total Bill</Th>
              
    <Th>Discount</Th>
    <Th>Discount%</Th>
    <Th>Cash</Th>
    <Th>Mode</Th>
    <Th>Online</Th>

              
              <Th>Created At</Th>
             
            </Tr>
          </Thead>
          <Tbody>
            {filteredData.map((order, index) => (
              <Tr key={index}>
                <Td><Table size='xsm' variant='striped' colorScheme='orange'>
                    <Thead>
                        <Tr>
                            <Td>Name</Td>
                            <Td>Quantity</Td>
                            <Td>Price</Td>
                        </Tr>
                    </Thead>
                    <Tbody>
                        
                            {order.foodItem.map((item, index) => (
                                <Tr>
                                    <Td>{item.name}</Td>
                                    <Td>{item.quantity}</Td>
                                    <Td>{item.price}</Td>
                                    </Tr>
                                
                            ))}
                        
                    </Tbody>
                </Table></Td>
                <Td>
                <Table size='xsm' variant='striped' colorScheme='yellow'>
                    <Thead>
                        <Tr>
                            <Td>Name</Td>
                            <Td>Quantity</Td>
                            <Td>Price</Td>
                        </Tr>
                    </Thead>
                    <Tbody>
                        
                            {order.wineItem.map((item, index) => (
                                <Tr>
                                    <Td>{item.name}</Td>
                                    <Td>{item.quantity}</Td>
                                    <Td>{item.price}</Td>
                                    </Tr>
                                
                            ))}
                        
                    </Tbody>
                </Table>
                </Td>
                <Td>{order.name}</Td>
                <Td>{order.phoneNo}</Td>
                <Td>{order.billNo}</Td>
                <Td>{order.tableNo}</Td>
                <Td>{order.totalBilling}</Td>
                <Td>{order.discount}</Td>
                <Td>{order.discountPercentage}</Td>
                <Td>{order.cash}</Td>
                <Td>{order.mode}</Td>
                <Td>{order.online}</Td>

                {/* <Td>{order.status ? "Due" : "Paid"}</Td>
                <Td onClick={()=>handlePayment(order._id)}> <Button colorScheme='green'  onClick={()=>handlePayment(order._id)}>Change</Button></Td> */}
                <Td>{new Date(order.createdAt).toLocaleString('en-GB')}</Td>
                <Td>
                  <Button colorScheme="blue" size="sm" onClick={() => handlePrint(order)}>Print</Button>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default QuickBillData;
