import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Box,
  Input,
  Button,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  FormControl,
  FormLabel,
  Select, Text
} from "@chakra-ui/react";

import toast from 'react-hot-toast';

const StoreData = () => {
  const [store, setStore] = useState([]);
  const [storeName, setStoreName] = useState('GoDown');
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const [data, setData] = useState([]);
 
  const customerId = localStorage.getItem('customerId');

  const fetchData = async () => {
    try {
      const response = await axios.get(`https://api.rcb.tdrsoftware.in/api/getStoreItems/${customerId}/${storeName}`);
      // Check if items exist in the response
      if (response.data.items.length > 0) {
        setData(response.data.items);
        setFilteredData(response.data.items);
        toast.success(response.data.message);
      } else {
        // If no items found, set data and filteredData to empty arrays
        setData([]);
        setFilteredData([]);
        toast.error('No items found in this store');
      }
    } catch (error) {
      toast.error('Failed to fetch data');
    }
  };

  const fetchData2 = async () => {
    try {
      const response = await axios.get(`https://api.rcb.tdrsoftware.in/api/store/${customerId}`);
      setStore(response.data);
    } catch (error) {
      toast.error('Failed to fetch stores');
    }
  };

  const handleChange = (e) => {
    const selectedStoreName = e.target.value;
    setStoreName(selectedStoreName);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    filterData(event.target.value);
  };

  const filterData = (term) => {
    let filtered = data;

    if (term) {
      filtered = filtered.filter(item =>
        item.itemName.toLowerCase().includes(term.toLowerCase())
      );
    }
    setFilteredData(filtered);
  };

  useEffect(() => {
    fetchData();
  }, [storeName]);

  useEffect(() => {
    fetchData2();
  }, [customerId]);

  return (
    <Box>
      <Box display='flex' flexDirection='row' justifyContent='center' alignItems='center' mt={4} mb={4} p={4} gap={10}>
        <Input
          w='30%'
          type="text"
          placeholder="Search Item name"
          value={searchTerm}
          onChange={handleSearchChange}
        />
        <Select w='30%' name='storeName' value={storeName} onChange={handleChange} >
          {store.map((store, index) => (
            <option key={index} value={store.name}>{store.name}</option>
          ))}
        </Select>
      </Box>

      <TableContainer width='full'>
        <Table size='sm' variant='striped' colorScheme='yellow'>
          <TableCaption>Store Data</TableCaption>
          <Thead>
            <Tr>
              <Th>Item Name</Th>
              <Th>Purchase Rate</Th>
              <Th>Quantity</Th>
              <Th>Unit</Th>
              <Th>Sgst</Th>
              <Th>Cgst</Th>
              <Th>Amount</Th>
            </Tr>
          </Thead>
          <Tbody>
            {filteredData.length > 0 ? (
              filteredData.map((item, index) => (
                <Tr key={index}>
                  <Td>{item.itemName}</Td>
                  <Td>{item.purchaseRate}</Td>
                  <Td>{item.quantity}</Td>
                  <Td>{item.unit}</Td>
                  <Td>{item.sgst}</Td>
                  <Td>{item.cgst}</Td>
                  <Td>{item.amount}</Td>
                </Tr>
              ))
            ) : (
              <Tr>
                <Td colSpan={7} textAlign="center">
                  No items found in this store
                </Td>
              </Tr>
            )}
          </Tbody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default StoreData;
