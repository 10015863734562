import React from "react";
import { Box } from "@chakra-ui/react";
import Navbar from "../components/Navbar/Navbar";
import Sidebar from "../components/Sidebar";
import { Route, Routes } from "react-router-dom";
import PrintData from "../modules/print/printData.js";
import TableRegister from "../modules/Table/tableRegister.js";
import TableStatus from "../modules/Table/tableStatus.js";
import WineRegister from "../modules/Wine/wineRegister.js";
import WineData from "../modules/Wine/wineData.js";
import CategoryRegister from "../modules/category/categoryRegister.js";
import FoodRegister from "../modules/food/foodRegister.js";
import FoodData from "../modules/food/foodData.js";
import RestaurentData from "../modules/restaurant/bill.js";
import QuickBillData from "../modules/food/quickBillData.js";
import KotBillData from "../modules/food/kotBillData.js";
import Cookie from 'js-cookie';
import { BillItemsProvider } from "../modules/context/tableItemsContext.js";
import { KotProvider } from "../modules/context/kotContext.js";
import ForgotPassword from "../modules/auth/Forgot/forgotPassword.js";
import GenerateOtp from "../modules/auth/Forgot/generateOtp.js";
import SupplyRegister from "../modules/inventory/supplyRegister.js";
import ItemRegister from "../modules/inventory/itemRegister.js";
import StoreRegister from "../modules/inventory/storeRegister.js";
import StaffRegister from "../modules/inventory/staffRegister.js";
import PurchaseRegister from "../modules/inventory/purchaseRegister.js";
import StoreData from "../modules/inventory/storeData.js";
import StockTransfer from "../modules/inventory/stockTransfer.js";
import PurchaseReport from "../modules/inventory/purchaseReport.js";
import Dashboard from "../modules/dashboard/index.js";
import Summary from "../modules/summary/summary.js";
import FoodData102 from "../modules/food/foodData102.js";
import FoodData103 from "../modules/food/foodData103.js";
import FoodData104 from "../modules/food/foodData104.js";

const Layout = () => {
  const token = Cookie.get('token');
  
  return (
    <Box width='full' h='full'>
      <Box display="flex">
        {token && <Sidebar />} {/* Show Sidebar if token exists */}
        <Box display='flex' flexDirection='column' width='100%'>
          {token && <Navbar />} {/* Show Navbar if token exists */}
          <Box overflowX='auto'>
            <BillItemsProvider>
              <KotProvider>
                <Routes>
                  {/* Protected Routes - Accessible only if token exists */}
                  {token ? (
                    <>
                      <Route path="/printRegister" element={<PrintData />} />
                      <Route path="/table-register" element={<TableRegister />} />
                      <Route path="/table-status" element={<TableStatus />} />
                      <Route path="/wine-register" element={<WineRegister />} />
                      <Route path="/wineData" element={<WineData />} />
                      <Route path="/category-register" element={<CategoryRegister />} />
                      <Route path="/food-register" element={<FoodRegister />} />
                      <Route path="/food" element={<FoodData />} />
                      <Route path="/restaurant" element={<RestaurentData />} />
                      <Route path="/quickBill" element={<QuickBillData />} />
                      <Route path="/kotBill" element={<KotBillData />} />
                      
                      <Route path="/supply-register" element={<SupplyRegister/>}/>
              <Route path="/item-register" element={<ItemRegister/>}/>
              <Route path="/store-register" element={<StoreRegister/>}/>
              <Route path="/staff-register" element={<StaffRegister/>}/>
              <Route path="/purchase-register" element={<PurchaseRegister/>}/>
              <Route path="/store" element={<StoreData/>}/>
              <Route path="/stock-transfer" element={<StockTransfer/>}/>
              <Route path="/purchase-report" element={<PurchaseReport/>}/>
              <Route path="/dashboard" element={<Dashboard/>}/>
              <Route path="/summary" element={<Summary/>}/>
                    </>
                  ) : (
                    <>
                      {/* Public Route for Forgot Password */}
                      <Route path="/forgot-password" element={<ForgotPassword />} />
                      <Route path="/generate-otp" element={<GenerateOtp />} />
                      <Route path="/foodData102" element={<FoodData102 />} />
                      <Route path="/foodData103" element={<FoodData103 />} />
                      <Route path="/foodData104" element={<FoodData104 />} />
                    </>
                  )}
                </Routes>
              </KotProvider>
            </BillItemsProvider>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Layout;
