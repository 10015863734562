import React, { useState, useEffect, useCallback } from 'react';
import { Box, Button, SimpleGrid, Text, Tooltip } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import toast from 'react-hot-toast';
import { useBillItems } from '../context/tableItemsContext';
import { debounce } from 'lodash';
import io from 'socket.io-client';

const socket = io('https://api.rcb.tdrsoftware.in');

const TableStatus = () => {
  const customerId = localStorage.getItem('customerId');
  const [order, setOrder] = useState({}); // Store orders for each table
  const [toggle, setToggle] = useState(false);
  const { billItems, fetchTableBillItems } = useBillItems();

  const [filter, setFilter] = useState({
    ac: '',
    status: '',
  });
  const [data, setData] = useState([]);

  const navigate = useNavigate();

  const filteredData = data.filter((item) => {
    return (
      (filter.ac === '' || item.ac === filter.ac) &&
      (filter.status === '' || item.status === filter.status)
    );
  });

  const handleToggle = () => {
    setToggle(!toggle);
  };

  const handleAcFilter = (ac) => {
    setFilter({ ...filter, ac });
  };

  const handleStatusFilter = (status) => {
    setFilter({ ...filter, status });
  };

  const fetchData = async () => {
    try {
      const response = await axios.get(`https://api.rcb.tdrsoftware.in/api/getTableData/${customerId}`);
      setData(response.data.table);

      // Fetch order data for each table
      response.data.table.forEach(table => {
        fetchTableData(table.tableNo);  // Call fetchTableData for each table
      });
    } catch (error) {
      toast.error(error.message);
    }
  };

  const fetchTableData = async (tableNo) => {
    try {
      const response = await axios.get(`https://api.rcb.tdrsoftware.in/api/getItems/${customerId}/${tableNo}`);
      setOrder(prev => ({
        ...prev,
        [tableNo]: response.data.items,  // Map orders based on tableNo
      }));
    } catch (error) {
      // toast.error(error.message);
    }
  };

  useEffect(() => {
    fetchData();
  }, [customerId]);

  useEffect(() => {
    // Listen for KOT generation events
    socket.on('kotGenerated', (data) => {
      toast.custom((t) => (
        <div style={{
          position: 'relative',
          padding: '16px',
          backgroundColor: '#4CAF50',
          color: '#fff',
          borderRadius: '4px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}>
          <span>{data.message}</span>
          <button
            onClick={() => toast.dismiss(t.id)}
            style={{
              background: 'none',
              border: 'none',
              color: '#fff',
              cursor: 'pointer',
              fontSize: '16px',
            }}
          >
            &#10005; {/* Close icon */}
          </button>
        </div>
      ), {
        icon: '📢',
        duration: Infinity, // Keep the toast on screen until manually dismissed
      });
      console.log(`Received KOT event: ${data.message}`, 'msg');
    });

    return () => {
      socket.off('kotGenerated'); // Clean up the listener on component unmount
    };
  }, []);

  return (
    <Box p={10}>
      <Box mb={4} display="flex" justifyContent="center" alignItems="center" gap={2}>
        <Button fontSize="sm" colorScheme={filter.status === '' ? 'blue' : 'gray'} onClick={() => handleStatusFilter('')}>
          All
        </Button>
        <Button fontSize="sm" colorScheme={filter.ac === 'Ac' ? 'blue' : 'gray'} onClick={() => handleAcFilter('Ac')}>
          Ac
        </Button>
        <Button fontSize="sm" colorScheme={filter.ac === 'Non-Ac' ? 'blue' : 'gray'} onClick={() => handleAcFilter('Non-Ac')}>
          Non-Ac
        </Button>
      </Box>

      <SimpleGrid columns={10} placeItems="center" mb={3} gap={5} boxShadow="2xl" p={3} borderRadius="6px">
        {filteredData.map((table) => {
          const tableOrder = order[table.tableNo] || []; // Get the order for the specific table
          const tooltipLabel = tableOrder.length > 0
            ? `${tableOrder.map((item) => `${item.name} x${item.quantity}`).join(', ')} | Total: ₹${tableOrder.reduce((total, item) => total + item.price, 0)}`
            : 'No items ordered';

          return (
            <Tooltip key={table.tableNo} label={tooltipLabel} aria-label="Ordered items">
              <Box
                border={!table.status ? '1px dotted green' : '1px dotted red'}
                bg={tooltipLabel === "No items ordered" ? "green" : "yellow"}
                borderRadius="8px"
                p={5}
                onClick={() =>
                  navigate('/restaurant', {
                    state: {
                      tableNo: table.tableNo,
                      ac: table.ac,
                    },
                  })
                }
                color={tooltipLabel === "No items ordered" ? "white" : "black"}
                textAlign="left"
                cursor="pointer"
              >
                <Text>{table.tableNo}</Text>
              </Box>
            </Tooltip>
          );
        })}
      </SimpleGrid>
    </Box>
  );
};

export default TableStatus;
