import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { loginUser, registerUser } from '../../modules/auth/api'; // Import your API functions
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';

// Define async thunks

export const loginAsync = createAsyncThunk(
    'auth/login',
    async (credentials) => {
        try {
            const response = await loginUser(credentials);
          //  console.log('Token from response:', response.data.user.token);
            return {
                ...response.data,
                customerId: credentials.customerId, // Include customerId in the payload
                password: credentials.password,
                     // Include password if needed (not recommended for security)
            };
        } catch (error) {
            console.error('Login API error:', error);
            throw error;
        }
    }
);

export const registerAsync = createAsyncThunk(
    'auth/register',
    async (userData) => {
        try {
            const response = await registerUser(userData);
            return {
                ...response.data,
                customerId: userData.customerId, // Include customerId in the payload
                password: userData.password,     // Include password if needed (not recommended for security)
            };
        } catch (error) {
            console.error('Register API error:', error);
            throw error;
        }
    }
);

// Define the slice
const authReducer = createSlice({
    name: 'auth',
    initialState: {
        user: null,
        customerId: null,
        token: null,
        loading: false,
        error: null,
    },
    reducers: {
        logout: (state) => {
            state.user = null;
            state.customerId = null;
            state.token = null;
            localStorage.removeItem('customerId');
            Cookies.remove('token');
            
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(loginAsync.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(loginAsync.fulfilled, (state, action) => {
                state.loading = false;
                state.user = action.payload.user;
                state.customerId = action.payload.customerId;
                state.token = action.payload.token;
                console.log(action.payload)
                // Do not store password in the state
            })
            .addCase(loginAsync.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(registerAsync.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(registerAsync.fulfilled, (state, action) => {
                state.loading = false;
                state.user = action.payload.user;
                state.customerId = action.payload.customerId;
                state.token = action.payload.token;
                // Do not store password in the state
            })
            .addCase(registerAsync.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            });
    },
});

// Export actions and reducer
export const { logout } = authReducer.actions;
export default authReducer.reducer;
