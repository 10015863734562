import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Text,
  SimpleGrid,
  Box,
  Input,
  FormLabel,Select,
  InputGroup,
  InputLeftAddon,useDisclosure,
  Checkbox,
  

} from '@chakra-ui/react';
import { useState,useEffect } from 'react';
import { IoMdClose } from "react-icons/io";
import axios from 'axios'
import toast from 'react-hot-toast';

const FoodUpdateModal = ({isOpen,onClose,id,customerId,onOpen,unique,setSelectId,data}) => {
    
    console.log(id,'id')    
    const [formData,setFormData]=useState({
        name: "",
        description: "",
        category:"",
       
        acRate: 0,
        nonAcRate:0,
        sgst: 0,
        cgst: 0,
        nonAcSgst:0,
        nonAcCgst:0,
        acHalfRate:0,
        acHalfSgst:0,
        acHalfCgst:0,
        nonAcHalfRate:0,
        nonAcHalfCgst:0,
        nonAcHalfSgst:0,
  
  
       
  
        image: "",
        type:'',code:'',
        halfPlate:false
       
    
    
    })
    
    console.log(unique,'unique')
    

    const handleEdit = async (e) => {
        e.preventDefault();
        
        try {
            // Make the PUT request with formData directly
            const response = await axios.put(`https://api.rcb.tdrsoftware.in/api/updateFoodData/${customerId}/${id}`,{data:formData});
            
            toast.success(response.data.message);
            console.log(response, 'response');
            //setSelectId(null);
            // Optionally reset the form after a successful update
            setFormData({
                name: "",
        description: "",
        category:"",
       
        acRate: 0,
        nonAcRate:0,
        sgst: 0,
        cgst: 0,
        nonAcSgst:0,
        nonAcCgst:0,
        acHalfRate:0,
        acHalfSgst:0,
        acHalfCgst:0,
        nonAcHalfRate:0,
        nonAcHalfCgst:0,
        nonAcHalfSgst:0,
  
  
       
  
        image: "",
        type:'',code:'',
        halfPlate:false
            });
            window.location.reload();
    
        } catch (error) {
            toast.error("Error updating Food Data: " + error.message);
            console.error("Update failed:", error);
        }
    };
   
   console.log(Object.keys(data)+" :"+Object.values(data),'data')
   

    useEffect(() => {
        if (data) {
            setFormData({
                name: data.name || "",
                description: data.description || "",
                category: data.category || "",
               
                acRate: data.acRate || 0,
                nonAcRate: data.nonAcRate || 0,
                sgst: data.sgst || 0,
                cgst: data.cgst || 0,
                nonAcSgst: data.nonAcSgst || 0,
                nonAcCgst: data.nonAcCgst || 0,
                acHalfRate: data.acHalfRate || 0,
                acHalfSgst: data.acHalfSgst || 0,
                acHalfCgst: data.acHalfCgst || 0,
                nonAcHalfRate: data.nonAcHalfRate || 0,
                nonAcHalfCgst: data.nonAcHalfCgst || 0,
                nonAcHalfSgst: data.nonAcHalfSgst || 0,
          
          
               
          
                image: data.image || "",
                type:data.type || '',code:data.code || '',
                halfPlate:data.halfPlate || false,
                
            });
        }
    }, [data]);
    const scroll='inside'
   
    const handleInputChange=(e)=>{
     const {name,value}=e.target;
     setFormData({
        ...formData,
       [name] : value 
     })
    }
    const handleCheckboxChange = (e) => {
        const { name, checked } = e.target;
        setFormData((prevFormData) => ({
          ...prevFormData,
          [name]: checked, // Set formData.halfPlate to true/false based on checkbox state
        }));
      };
      const generateGst=()=>{
        setFormData((prev)=>({
            ...prev,
            sgst:prev.acRate*0.025,
            cgst:prev.acRate*0.025,
            nonAcSgst:prev.nonAcRate*0.025,
            nonAcCgst:prev.nonAcRate*0.025,
            acHalfSgst:prev.acHalfRate*0.025,
            acHalfCgst:prev.acHalfRate*0.025,
            nonAcHalfSgst:prev.nonAcHalfRate*0.025,
            nonAcHalfCgst:prev.nonAcHalfRate*0.025,
        }))
      }
    const handleSubmit=(e)=>{
       
    }
    const handleClick=()=>{
      onClose()
      setSelectId(null);
    }
  return (
    <Modal  width='full' size='3xl' isOpen={isOpen} onClose={onClose} onOpen={onOpen} scrollBehavior={scroll}  >
      <ModalOverlay />
      <ModalContent bg='#f9f1e3'>
        <ModalHeader display='flex' justifyContent='space-between' alignItems='center'>
            <Text>Cash Invoice- {data?.name}</Text>
            <Button colorScheme='red' onClick={handleClick} ><IoMdClose/></Button></ModalHeader>
       
        <ModalBody >
         <SimpleGrid columns={2} gap={4} placeItems='left'>
            <InputGroup>
            <InputLeftAddon>Name</InputLeftAddon>
           <Input  bg='white' onChange={handleInputChange} name='name' placeholder='Enter name' value={formData.name} />
           </InputGroup>
           <InputGroup>
            <InputLeftAddon>Description</InputLeftAddon>
           <Input  bg='white' onChange={handleInputChange} name='description' placeholder='Enter Description' value={formData.description} />
           </InputGroup>
           <InputGroup>
            <InputLeftAddon>Category</InputLeftAddon>
           <Select width='70%' type='date' bg='white' onChange={handleInputChange} name='category' placeholder='Enter Category' value={formData.category} >
           {unique.map((name)=>(<option key={name._id} value={name}>{name}</option>))} 
           </Select>
            
           </InputGroup>
           <InputGroup>
            <InputLeftAddon>Ac Rate</InputLeftAddon>
           <Input  bg='white' onChange={handleInputChange} name='acRate' placeholder='Enter Ac Rate' value={formData.acRate} />
           </InputGroup>
           
           {formData.halfPlate ?<InputGroup> <InputLeftAddon>Ac Half Rate</InputLeftAddon>
           <Input  bg='white' onChange={handleInputChange} name='acHalfRate' placeholder='Enter Ac Half Rate' value={formData.acHalfRate} />
           </InputGroup> : ""}
           <InputGroup>
            <InputLeftAddon>NonAc Rate</InputLeftAddon>
           <Input width='68%' bg='white' onChange={handleInputChange} name='nonAcRate' placeholder='Enter NonAc Rate' value={formData.nonAcRate} />
           </InputGroup>
           {formData.halfPlate ?<InputGroup> <InputLeftAddon>NonAc Half Rate</InputLeftAddon>
           <Input  bg='white' onChange={handleInputChange} name='NonAcHalfRate' placeholder='Enter NonAc Half Rate' value={formData.nonAcHalfRate} />
           </InputGroup> : ""}
           <InputGroup>
            <InputLeftAddon>Sgst</InputLeftAddon>
           <Input width='68%' bg='white' onChange={handleInputChange} name='sgst' placeholder='Enter Sgst' value={formData.sgst} />
           </InputGroup>
           <InputGroup>
            <InputLeftAddon>Cgst</InputLeftAddon>
           <Input width='68%' bg='white' onChange={handleInputChange} name='cgst' placeholder='Enter Cgst' value={formData.cgst} />
           </InputGroup>
           {formData.halfPlate ?<InputGroup> <InputLeftAddon>Ac Half Cgst</InputLeftAddon>
           <Input  bg='white' onChange={handleInputChange} name='acHalfCgst' placeholder='Enter Ac Half Cgst' value={formData.acHalfCgst} />
           </InputGroup> : ""}
           {formData.halfPlate ?<InputGroup> <InputLeftAddon>Ac Half Sgst</InputLeftAddon>
           <Input  bg='white' onChange={handleInputChange} name='acHalfSgst' placeholder='Enter Ac Half Sgst' value={formData.acHalfSgst} />
           </InputGroup> : ""}
           
           <InputGroup>
            <InputLeftAddon>NonAc Sgst</InputLeftAddon>
           <Input width='68%' bg='white' onChange={handleInputChange} name='nonAcSgst' placeholder='Enter NonAc Cgst' value={formData.nonAcCgst} />
           </InputGroup>
           <InputGroup>
            <InputLeftAddon>NonAc Cgst</InputLeftAddon>
           <Input width='68%' bg='white' onChange={handleInputChange} name='nonAcCgst' placeholder='Enter NonAc Sgst' value={formData.nonAcSgst} />
           </InputGroup>
           {formData.halfPlate ?<InputGroup> <InputLeftAddon>NonAc Half Cgst</InputLeftAddon>
           <Input  bg='white' onChange={handleInputChange} name='nonAcHalfCgst' placeholder='Enter NonAc Half Cgst' value={formData.nonAcHalfCgst} />
           </InputGroup> : ""}
           {formData.halfPlate ?<InputGroup> <InputLeftAddon>NonAc Half Sgst</InputLeftAddon>
           <Input  bg='white' onChange={handleInputChange} name='nonAcHalfSgst' placeholder='Enter NonAc Half Sgst' value={formData.nonAcHalfSgst} />
           </InputGroup> : ""}
           <InputGroup>
            {/* <InputLeftAddon>Half Plate Available?</InputLeftAddon> */}
            <Checkbox w='70%' bg='#fafafa' value={formData.halfPlate} name='halfPlate' isChecked={formData.halfPlate} onChange={handleCheckboxChange}>Half Available ?</Checkbox>
          
           </InputGroup>
           <InputGroup>
            <InputLeftAddon>Code</InputLeftAddon>
           <Input width='68%' bg='white' onChange={handleInputChange} name='code' placeholder='Enter Code' value={formData.code} />
           </InputGroup>
          
          
           

         </SimpleGrid>
         <Box display='flex' alignItems='center' justifyContent='center' gap={4} mt={2}>
         <Button colorScheme='yellow' onClick={generateGst} >Generate Gst</Button>
         <Button colorScheme='green' onClick={(e)=>handleEdit(e)} >Edit</Button>
         </Box>
        </ModalBody>

        <ModalFooter>
         
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default FoodUpdateModal;
