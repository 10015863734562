import React,{useState,useEffect} from 'react';
import { Box, VStack, Button, SimpleGrid, Stack, Text, Card, CardBody, Image, Input,CardHeader,CardFooter,Select,Table,
    Thead,
    Tbody,
    Tfoot,
    Tr,
    Th,
    Td,
    TableCaption,
    TableContainer,Flex, 
    GridItem,Grid,ButtonGroup,
    OrderedList,ListItem,useDisclosure} from "@chakra-ui/react";
import { FaRupeeSign } from 'react-icons/fa';
import axios from 'axios';
import toast from 'react-hot-toast';


const Summary =()=>{
    const [data,setData]=useState([]);
    const customerId=localStorage.getItem('customerId')
    const fetchData=async()=>{
        try {
            const response=await axios.get(`https://api.rcb.tdrsoftware.in/api/getQuickBillData/${customerId}`);
            setData(response.data.quickBill);
            
        } catch (error) {
            toast.error(error.message);
        }
    }
    console.log(data,'data')
    const foodBill = data?.reduce((acc,item) => 
       acc+ (item.foodItem)?.reduce((acc, foodItem) => acc + (foodItem.price ), 0),0
      );
      const wineBill = data?.reduce((acc,item) => 
        acc+ (item.wineItem)?.reduce((acc, wineItem) => acc + (wineItem.price ), 0),0
       );
       const totalBill=data?.reduce((acc,item) =>acc+ (item.totalBilling),0);
      console.log(foodBill);
      const foodTax=foodBill*0.05;
      const cash=data?.reduce((acc,item) =>acc+ (item.cash),0);
      const online=data?.filter(item=>item.mode !== 'Card').reduce((acc,item) =>acc+ (item.online),0);
      console.log(online,cash,'cash')
      const card=data?.filter(item=>item.mode == 'Card').reduce((acc,item) =>acc+ (item.online),0);
      
  // const foodBill=0;
    useEffect(()=>{
        fetchData();
    },[customerId])
    return (
         <Box p={10}>
          <TableContainer>
          <Table variant='striped' colorScheme='teal'>
          <TableCaption>Bill Summary</TableCaption>
          <Thead>
          <Tr>
          <Th>FoodBill</Th>
          <Th>WineBill</Th>
          <Th>TotalBill</Th>
          <Th>FoodTax</Th>
          <Th>WineTax</Th>
          <Th>Cash</Th>
          <Th>Online</Th>
          <Th>Card</Th>
          <Th>Complementery</Th>
          <Th>Allowed By</Th>
          </Tr>

          </Thead>
          <Tbody>
            <Tr>
            <Td>{foodBill}</Td>
            <Td>{wineBill}</Td>
            <Td>{totalBill}</Td>
            <Td>{foodTax}</Td>
            <Td>0</Td>
            <Td>{cash}</Td>
            <Td>{online}</Td>
            <Td>{card}</Td>
            <Td>NA</Td>
            <Td>NA</Td>
            </Tr>
          </Tbody>
          </Table>

          </TableContainer>

          <Box display='flex' bg='#F5AB98' flexDirection='row'  justifyContent='center' alignItems='center' height='fit-content' p={2} border='2px solid tomato'>
          <Box  display='flex' flexDirection='column' justifyContent='space-between' alignItems='center' width='50%' height='fit-content'  p={2} >
          <Box display='flex' justifyContent='space-evenly' alignItems='center'  width='full'  gap={30}> 
          <Box display='flex' flexDirection='column' >
           <Text fontSize='md' as='b'>Total Gross Food Sale</Text>
           <Text fontSize='md' as='b'>Total Food Discount</Text>
           <Text fontSize='md' as='b'>Total Food Complementery</Text>
           <Text fontSize='md' as='b'>Total Sale NonTaxable</Text>
           <Text fontSize='md' mt={2} borderTop='1px dotted black' as='b'>Net Food Sale Taxable</Text>
          </Box>
          <Box display='flex' flexDirection='column' >
           <Text fontSize='md' as='b'>{foodBill}</Text>
           <Text fontSize='md' as='b'>0</Text>
           <Text fontSize='md' as='b'>0</Text>
           <Text fontSize='md' as='b'>0</Text>
           <Text fontSize='md' mt={2} borderTop='1px dotted black' as='b'>{foodBill}</Text>
          </Box>


          </Box>
          <Box display='flex' justifyContent='space-evenly' alignItems='center'  width='full' gap={30} mt={10}> 
          <Box display='flex' flexDirection='column' >
           <Text fontSize='md' as='b'>Food Service Charge</Text>
           <Text fontSize='md' as='b'>Food Vat</Text>
           <Text fontSize='md' as='b'>Food CGST</Text>
           <Text fontSize='md' as='b'>Food SGST</Text>
           <Text fontSize='md' as='b'>Food ESTD</Text>
           <Text fontSize='md' as='b'>Food GST</Text>
           <Text fontSize='md' as='b'>Food Round Off</Text>
           <Text fontSize='md' as='b'>Total Food Non Taxable</Text>
           <Text fontSize='md' mt={2} borderTop='1px dotted black' as='b'>Food Net Amount</Text>
          
          </Box>
          <Box display='flex' flexDirection='column' >
           <Text fontSize='md' as='b'>0</Text>
           <Text fontSize='md' as='b'>0</Text>
           <Text fontSize='md' as='b'>{foodTax/2}</Text>
           <Text fontSize='md' as='b'>{foodTax/2}</Text>
           <Text fontSize='md' as='b'>0</Text>
           <Text fontSize='md' as='b'>{foodTax}</Text>
           <Text fontSize='md' as='b'>0</Text>
           <Text fontSize='md' as='b'>0</Text>
           <Text fontSize='md' mt={2} borderTop='1px dotted black' as='b'>{foodBill + foodTax}</Text>
          </Box>


          </Box>



          </Box>
          <Box  display='flex' flexDirection='column' justifyContent='space-between' width='50%' alignItems='center' height='fit-content'  p={2} >
          <Box display='flex' justifyContent='space-evenly' alignItems='center'  width='full' gap={30}> 
          <Box display='flex' flexDirection='column' >
           <Text fontSize='md' as='b'>Total Gross Wine Sale</Text>
           <Text fontSize='md' as='b'>Total Wine Discount</Text>
           <Text fontSize='md' as='b'>Total Wine Complementery</Text>
           <Text fontSize='md' as='b'>Wine Sale Non Taxable</Text>
           <Text fontSize='md' mt={2} borderTop='1px dotted black' as='b'>Net Wine Sale Taxable</Text>
          </Box>
          <Box display='flex' flexDirection='column' >
           <Text fontSize='md' as='b'>{wineBill}</Text>
           <Text fontSize='md' as='b'>0</Text>
           <Text fontSize='md' as='b'>0</Text>
           <Text fontSize='md' as='b'>0</Text>
           <Text fontSize='md' mt={2} borderTop='1px dotted black' as='b'>0</Text>
          </Box>


          </Box>
          <Box display='flex' justifyContent='space-evenly' alignItems='center'  width='full' mt={10} gap={30}> 
          <Box display='flex' flexDirection='column' >
           <Text fontSize='md' as='b'> Wine Service Charge</Text>
           <Text fontSize='md' as='b'>Wine Vat</Text>
           <Text fontSize='md' as='b'>Wine CGST</Text>
           <Text fontSize='md' as='b'>Wine SGST</Text>
           <Text fontSize='md' as='b'>Wine ESTD</Text>
           <Text fontSize='md' as='b'>Wine GST</Text>
           <Text fontSize='md' as='b'>Wine Round Off</Text>
           <Text fontSize='md' as='b'>Add Wine Sale Non Taxable</Text>
           <Text fontSize='md' mt={2} borderTop='1px dotted black' as='b'>Wine Net Amount</Text>
          </Box>
          <Box display='flex' flexDirection='column' >
           <Text fontSize='md' as='b'>0</Text>
           <Text fontSize='md' as='b'>0</Text>
           <Text fontSize='md' as='b'>0</Text>
           <Text fontSize='md' as='b'>0</Text>
           <Text fontSize='md' as='b'>0</Text>
           <Text fontSize='md' as='b'>0</Text>
           <Text fontSize='md' as='b'>0</Text>
           <Text fontSize='md' as='b'>{wineBill}</Text>
           <Text fontSize='md' mt={2} borderTop='1px dotted black' as='b'>{wineBill}</Text>
          </Box>


          </Box>



          </Box>
          </Box>
             



         </Box>


    )
}
export default Summary;


