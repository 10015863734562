import {
  FormControl,
  FormLabel,
  Input,
  Button,
  Box,
  Text,
  TableContainer,
  Table,
  Thead,
  Tbody,
  Tr,
  Flex,
  Th,
  Td,
  TableCaption,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  useToast,
  Select,
} from "@chakra-ui/react";
import { useRef, useState, useEffect } from "react";
import { useReactToPrint } from "react-to-print";
import axios from "axios";
import toast from "react-hot-toast";

const PurchaseRegister = () => {
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const customerId = localStorage.getItem("customerId");
  const [data, setData] = useState([]);
  const [supply, setSupply] = useState([]);
  const [store, setStore] = useState([]);
  const [item,setItem]=useState([])
  const [isEditing, setIsEditing] = useState(false);
 // const [editItemId, setEditItemId] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [deleteItemId, setDeleteItemId] = useState(null);
  const [itemList, setItemList] = useState([]);
  const [selectedUnit,setSelectedUnit]=useState([])
  const cancelRef = useRef();
  const [itemFormData, setItemFormData] = useState({
    itemName: "",
    quantity: 0,
    unit: '',
    mrp: 0,
    purchaseRate: 0,
    discount: null,
    sgst: 0,
    cgst: 0,
    amount: 0,
  });
  const [formData, setFormData] = useState({
    name: "",
    balance: 0,
    billNo: null,
    address: "",
    challanNo: null,
    store: "",
    date: "",
    item: [
    
    ],
    cgstTotal: 0,
    sgstTotal: 0,
    grossAmount: 0,
    itemDiscount: 0,
    totalTax: 0,
    cashDiscountPercentage: 0,
    cashDiscountAmount: 0,
    adjustment: 0,
    netAmount: 0,
  });
  
  const [editingIndex, setEditingIndex] = useState(null);
  const [editingItem, setEditingItem] = useState(null);
  const handleEdit = (index) => {
    setEditingIndex(index);
    setEditingItem({ ...itemList[index] });
  };
  
  const handleEditChange = (e) => {
    const { name, value } = e.target;
    const updatedEditingItem = {
      ...editingItem,
      [name]: value,
    };
  
    // Calculate amount if certain fields are updated
    if (["quantity", "purchaseRate", "cgst", "sgst", "discount"].includes(name)) {
      const total =
        parseFloat(updatedEditingItem.quantity || 0) *
        (parseFloat(updatedEditingItem.purchaseRate || 0) +
          parseFloat(updatedEditingItem.cgst || 0) +
          parseFloat(updatedEditingItem.sgst || 0)) -
        parseInt(updatedEditingItem.discount || 0);
  
      updatedEditingItem.amount = total;
    }
  
    setEditingItem(updatedEditingItem);
  };
  
  const handleSave = (index) => {
    const updatedItemList = [...itemList];
    updatedItemList[index] = editingItem; // Update item in list
    setItemList(updatedItemList);
    setEditingIndex(null); // Exit edit mode
    setEditingItem(null); // Reset editing item state
  };
  
  const handleDeleteItem = (index) => {
    const updatedItemList = itemList.filter((_, i) => i !== index);
    setItemList(updatedItemList); // Update list after delete
  };

  const addItemToTable = (e) => {
   e.preventDefault();
    setItemList([...itemList, itemFormData]);
    setItemFormData({
      itemName: "",
      quantity: 0,
      unit: '',
      mrp: 0,
      purchaseRate: 0,
      discount: null,
      sgst: 0,
      cgst: 0,
      amount: 0,
    });
  };
  const handleAmountKeyPress = (e) => {
    if(itemFormData.quantity>0){
    if (e.key === "Enter" ) {
        e.preventDefault()
      addItemToTable(e); // Add the item to the table when Enter is pressed
     
       // Prevent the default action of the Enter key
    }}else{
      toast.error("Quantity should be greater that zero")
    }
  };

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `https://api.rcb.tdrsoftware.in/api/item/${customerId}`
      );
      setData(response.data.item);
    } catch (error) {
      toast.error(error.message);
    }
  };
  const supplierData = async () => {
    try {
      const response = await axios.get(
        `https://api.rcb.tdrsoftware.in/api/supply/${customerId}`
      );
      setSupply(response.data.supply);
    } catch (error) {
      toast.error(error.message);
    }
  };
  const storeData = async () => {
    try {
      const response = await axios.get(
        `https://api.rcb.tdrsoftware.in/api/store/${customerId}`
      );
      setStore(response.data);
    } catch (error) {
      toast.error(error.message);
    }
  }
  const changeUnit=()=>{

  }

  const itemData = async () => {
    try {
      const response = await axios.get(
        `https://api.rcb.tdrsoftware.in/api/item/${customerId}`
      );
      setItem(response.data.item);
    } catch (error) {
      toast.error(error.message);
    }
  }



  useEffect(() => {
    fetchData();
    supplierData();
    storeData();
    itemData()
  }, [customerId]);
  const handleItemChange = (e) => {
    const { name, value } = e.target;
    setItemFormData({
      ...itemFormData,
      [name]: value,
    });
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const validateForm = () => {
    const requiredFields = ["quantity"];
    for (const item of formData.item) {
      for (const field of requiredFields) {
        if (!item[field]) {
          return false;
        }
      }
    }
    return true;
  };

  const supplierChange = (item) => {
    console.log(item);
    setFormData((prevFormData) => ({
      ...prevFormData,
      balance: item.openingBalance,
      address: item.address,
    }));
  };
  const itemChange=(item)=>{
    setSelectedUnit([item.purchaseUnit,item.usageUnit])
    console.log(item,'item2')
    setItemFormData((prev)=>({
        ...prev,
        cgst:item.cgst,
        sgst:item.sgst,
       
    }))
    console.log(itemFormData.unit,'unit')
  }
  //console.log(itemFormData.unit,'unit')
  

  const save = async (e) => {
    e.preventDefault();
    if (!validateForm()) {
      toast.error("Please fill the quantity field.");
      return;
    }
   

    try {
      const response = await axios.post(
        "https://api.rcb.tdrsoftware.in/api/purchaseBill",
        {
          customerId: customerId,
          data: formData,
        }
      );
      console.log(formData)
      toast.success(response.data.message);

      setFormData({
        name: "",
        balance: 0,
        billNo: null,
        address: "",
        challanNo: null,
        store: "",
        date: "",
        item: [
          
        ],
        cgstTotal: 0,
        sgstTotal: 0,
        grossAmount: 0,
        itemDiscount: 0,
        totalTax: 0,
        cashDiscountPercentage: 0,
        cashDiscountAmount: 0,
        adjustment: 0,
        netAmount: 0,
      });
      setItemFormData({
        itemName: "",
    quantity: 0,
    unit: '',
    mrp: 0,
    purchaseRate: 0,
    discount: null,
    sgst: 0,
    cgst: 0,
    amount: 0,
      })
      setItemList([])
      fetchData();
    } catch (error) {
      toast.error(error.message);
    }
  };
  const amountFocus=()=>{
    if(itemFormData.quantity<=0){
      toast.error("Please enter valid quantity")
    }else{
    const total=parseFloat(itemFormData.quantity || 0) * (parseFloat(itemFormData.purchaseRate || 0) +parseFloat(itemFormData.cgst || 0)+parseFloat(itemFormData.sgst || 0))-parseInt(itemFormData.discount || 0)
    setItemFormData((prev)=>({
        ...prev,
        amount:total
    }))
  }
  }
  const amountEditFocus=(index)=>{
    console.log(index,'index')
    const total=parseFloat(itemList[index].quantity || 0) * (parseFloat(itemList[index].purchaseRate || 0) +parseFloat(itemList[index].cgst || 0)+parseFloat(itemList[index].sgst || 0))-parseInt(itemList[index].discount || 0)
   // const updatedItemList = [...itemList];
  console.log(total,'total')
  //itemList[index].amount=total
  console.log(itemList[index].quantity,'soda')
    if (itemList[index].amount !== total) {
      
      const updatedItemList = [...itemList];
      updatedItemList[index] = {
        ...updatedItemList[index],
        amount: total,
      };
      console.log(updatedItemList,'update')
  
      setItemFormData((prev) => ({
        ...prev,
        itemList: updatedItemList,
      }));
    }
  }
  console.log(itemList,'itemList')
  const handleDelete = async () => {
    try {
      const response = await axios.delete(
        `https://api.rcb.tdrsoftware.in/api/itemDelete/${customerId}/${deleteItemId}`
      );
      toast.success(response.data.message);
      setIsOpen(false);
      fetchData();
    } catch (error) {
      toast.error(error.message);
    }
  };
  const openModal = (id) => {
    setDeleteItemId(id);
    setIsOpen(true);
  };

  const onClose = () => {
    setIsOpen(false);
    setDeleteItemId(null);
  };
  console.log(itemList)
  const totalFocus=()=>{
    let cgstTotal=0,sgstTotal=0,grossAmount=0
    for(let item of itemList){
        cgstTotal+=item.cgst
        sgstTotal+=item.sgst
        grossAmount+=item.amount
    }
    setFormData((prev)=>({
        ...prev,
        cgstTotal:cgstTotal,
        sgstTotal:sgstTotal,
        grossAmount:grossAmount,
        totalTax:cgstTotal+sgstTotal
    }))
  }
  const OnNetAmountFocus=()=>{
    const totalDiscount=parseInt(formData.itemDiscount || 0) + parseInt(formData.grossAmount)*(parseInt(formData.cashDiscountPercentage)/100 || 0 ) +parseInt(formData.adjustment)+parseInt(formData.cashDiscountAmount||0);
    const Sumtotal=parseInt(formData.grossAmount)-totalDiscount;
    setFormData((prev)=>({
        ...prev,
        netAmount:Sumtotal,
        item:[...itemList]
    }))

  }

  return (
    <Box
      bg="#EAE5DF"
      p="10"
      textAlign="center"
      color="black"
      width="100%"
      height="fit-content"
    >
      <Text
        fontSize="2xl"
        fontWeight="bold"
        textAlign="center"
        color="black"
        mb={10}
      >
        Purchase Register
      </Text>

      <FormControl isRequired onSubmit={save} fontSize="sm">
        <Box display="grid" gridGap={1}>
          {/* Form Fields */}
          <Box
            display="flex"
            gap="10px"
            alignItems="center"
            justifyContent="space-evenly"
          >
            <Box
              width="20%"
              display="flex"
              alignItems="center"
             
              borderRadius="5px"
              p={2}
            >
              <FormLabel fontSize="sm">Supplier Name</FormLabel>
              <Select
                size="sm"
                w="78%"
                bg="#FAFAFA"
                placeholder="Enter Item Name"
                name="name"
                value={formData.name}
                onChange={(e) => {
                  const selectedItem = supply.find(
                    (item) => item.name === e.target.value
                  );
                  handleInputChange(e); // To handle the name field change
                  if (selectedItem) {
                    supplierChange(selectedItem); // Trigger supplier change with the selected item
                  }
                }}
              >
                {supply?.map((item, index) => (
                  <option key={index} value={item.name}>
                    {item.name}
                  </option>
                ))}
              </Select>
            </Box>
            <Box
              width="20%"
              display="flex"
              alignItems="center"
              
              borderRadius="5px"
              p={2}
            >
              <FormLabel fontSize="sm">Balance</FormLabel>
              <Input
                size="sm"
                w="62%"
                bg="#FAFAFA "
                placeholder="Enter Balance"
                name="balance"
                value={formData.balance}
                onChange={handleInputChange}
              />
            </Box>
            <Box
              width="20%"
              display="flex"
              alignItems="center"
              
              borderRadius="5px"
              p={2}
            >
              <FormLabel fontSize="sm">Bill No</FormLabel>
              <Input
                size="sm"
                w="65%"
                bg="#FAFAFA "
                placeholder="Enter Bill No"
                name="billNo"
                value={formData.billNo}
                onChange={handleInputChange}
              />
            </Box>
            <Box
              width="20%"
              display="flex"
              alignItems="center"
             
              borderRadius="5px"
              p={2}
            >
              <FormLabel fontSize="sm">Address</FormLabel>
              <Input
                size="sm"
                w="60%"
                bg="#FAFAFA "
                placeholder="Enter Address"
                name="address"
                value={formData.address}
                onChange={handleInputChange}
              />
            </Box>
          </Box>
          <Box
            display="flex"
            gap="10px"
            alignItems="center"
            justifyContent="space-evenly"
          >
            <Box
              width="20%"
              display="flex"
              alignItems="center"
              
              borderRadius="5px"
              p={4}
            >
              <FormLabel fontSize="sm">ChallanNo</FormLabel>
              <Input
                size="sm"
                w="50%"
                bg="#FAFAFA "
                placeholder="ChallanNo"
                name="challanNo"
                value={formData.challanNo}
                onChange={handleInputChange}
              />
            </Box>
            <Box
              width="20%"
              display="flex"
              alignItems="center"
              
              borderRadius="5px"
              p={4}
            >
              <FormLabel fontSize="sm">Store</FormLabel>
              <Select
                size="sm"
                w="68%"
                bg="#FAFAFA "
                placeholder="Store Name"
                name="store"
                value={formData.store}
                onChange={handleInputChange}
              >
                 {store?.map((item,index)=>(
                    <option key={index}>{item.name}</option>
                 ))}
              </Select>
            </Box>
            <Box
              width="20%"
              display="flex"
              alignItems="center"
              
              
              borderRadius="5px"
              p={4}
            >
              <FormLabel fontSize="sm">Date</FormLabel>
              <Input
              type="date"
                size="sm"
                w="70%"
                bg="#FAFAFA "
                placeholder="Enter Date"
                name="date"
                value={formData.date}
                onChange={handleInputChange}
              />
            </Box>
          </Box>
          <Box
            display="flex"
            gap="10px"
            bg='#bdf2f1'
            p={2}
            alignItems="center"
            justifyContent="space-evenly"
          >
            <Box
              width="20%"
              display="flex"
              flexDirection='column'
              alignItems="left"
             bg='#e0ded7'
              border='1px solid skyblue'
              borderRadius="5px"
              p={2}
            >
              <FormLabel fontSize="xs">Item Name</FormLabel>
              <Select
                size="xs"
                w="100%"
                bg="#FAFAFA "
                placeholder="Item Name"
                name="itemName"
                value={itemFormData.itemName}
                onChange={(e) => {
                    const selectedItem = item.find(
                      (s) => s.name === e.target.value
                    );
                    handleItemChange(e); // To handle the name field change
                    if (selectedItem) {
                      itemChange(selectedItem); // Trigger supplier change with the selected item
                    }else{
                        console.log("not found")
                    }
                  }}
              >
                {item?.map((s,index)=>(
                    <option key={index} value={s.name}>{s.name}</option>
                ))}
              </Select>
            </Box>
      
            <Box
              width="20%"
              display="flex"
              flexDirection='column'
              alignItems="left"
              boxShadow="xl"
              bg='#e0ded7'
               border='1px solid skyblue'
              borderRadius="5px"
              p={2}
            >
              <FormLabel fontSize="xs">Quantity</FormLabel>
              <Input
              
                size="xs"
                w="100%"
                bg="#FAFAFA "
                placeholder="Enter Quantity"
                 
                name="quantity"
                value={itemFormData.quantity}
                onChange={handleItemChange}
              />
            </Box>
            <Box
              width="20%"
              display="flex"
              flexDirection='column'
              alignItems="left"
              bg='#e0ded7'
               border='1px solid skyblue'
              boxShadow="xl"
              borderRadius="5px"
              p={2

              }
            >
                
              <FormLabel fontSize="xs">Unit</FormLabel>
              <Flex>
              <Input
                size="xs"
                w="50%"
                bg="#FAFAFA "
                placeholder="Unit"
                name="unit"
                value={itemFormData.unit}
                onChange={handleItemChange}
              /><Select size="xs"
              w="50%"
              bg="#FAFAFA "
              placeholder="Unit" name='unit' value={itemFormData.unit} onChange={handleItemChange}>
                {selectedUnit.map((item,index)=>(
                    <option key={index}  value={item}>{item}</option>
                ))}
              </Select>
              </Flex>
                
            </Box>
            <Box
              width="20%"
              display="flex"
               flexDirection='column'
              alignItems="left"
              bg='#e0ded7'

              boxShadow="xl"
               border='1px solid skyblue'
              borderRadius="5px"
              p={2}
            >
              <FormLabel fontSize="xs">MRP</FormLabel>
              <Input
                size="xs"
                w="100%"
                bg="#FAFAFA "
                placeholder="MRP"
                name="mrp"
                value={itemFormData.mrp}
                onChange={handleItemChange}
              />
                 
            </Box>
            <Box
              width="20%"
              display="flex"
              flexDirection='column'
              alignItems="left"
              boxShadow="xl"
              bg='#e0ded7'
               border='1px solid skyblue'
              borderRadius="5px"
              p={2}
            >
              <FormLabel fontSize="xs">Purchase Rate</FormLabel>
              <Input
              
                size="xs"
                w="100%"
                bg="#FAFAFA "
                placeholder="purchaseRate"
                name="purchaseRate"
                value={itemFormData.purchaseRate}
                onChange={handleItemChange}
              />
            </Box>
            <Box
              width="20%"
              display="flex"
              flexDirection='column'
              alignItems="left"
              bg='#e0ded7'
              boxShadow="xl"
               border='1px solid skyblue'
              borderRadius="5px"
              p={2}
            >
              <FormLabel fontSize="xs">Discount</FormLabel>
              <Input
                size="xs"
                w="100%"
                bg="#FAFAFA "
                placeholder="Discount"
                name="discount"
                value={itemFormData.discount}
                onChange={handleItemChange}
              />
                
            </Box>
            <Box
              width="20%"
              display="flex"
               flexDirection='column'
              alignItems="left"
              bg='#e0ded7'
              boxShadow="xl"
               border='1px solid skyblue'
              borderRadius="5px"
              p={2}
            >
              <FormLabel fontSize="xs">Sgst</FormLabel>
              <Input
                size="xs"
                w="100%"
                bg="#FAFAFA "
                placeholder="Sgst"
                name="sgst"
                value={itemFormData.sgst}
                onChange={handleItemChange}
              />
                 
            </Box>
            <Box
              width="20%"
              display="flex"
              flexDirection='column'
              bg='#e0ded7'
              alignItems="left"
              boxShadow="xl"
               border='1px solid skyblue'
              borderRadius="5px"
              p={2}
            >
              <FormLabel fontSize="xs">Cgst</FormLabel>
              <Input
              
                size="xs"
                w="100%"
                bg="#FAFAFA "
                placeholder="Enter Cgst"
                name="cgst"
                value={itemFormData.cgst}
                onChange={handleItemChange}
              />
            </Box>
            <Box
              width="20%"
              display="flex"
              bg='#e0ded7'
              flexDirection='column'
              alignItems="left"
              boxShadow="xl"
               border='1px solid skyblue'
              borderRadius="5px"
              p={2}
            >
              <FormLabel fontSize="xs">Amount</FormLabel>
              <Input
              
                size="xs"
                w="100%"
                bg="#FAFAFA "
                placeholder="Enter Amount"
                name="amount"
                value={itemFormData.amount}
                onChange={handleItemChange}
                onFocus={amountFocus}
                onKeyPress={handleAmountKeyPress}
              />
            </Box>
          </Box>
         
        </Box>

        
      </FormControl>



      <TableContainer
        width="full"
        mt={10}
        border="1px solid black"
        p={4}
        bg="white"
        borderRadius="10px"
        fontSize="sm"
      >
        <Table size="sm" variant="striped" colorScheme="teal">
          <TableCaption>Item Data</TableCaption>
          <Thead>
            <Tr>
            
            <Th>Sl No</Th>
            <Th>Item Name</Th>
              <Th>Quantity</Th>
              <Th>Unit</Th>
              <Th>MRP</Th>
              <Th>Purchase Rate</Th>
              <Th>Discount</Th>
              <Th>SGST</Th>
              <Th>CGST</Th>
              <Th>Amount</Th>
              <Th>Edit</Th>
              <Th>Delete</Th>
            </Tr>
          </Thead>
          <Tbody>
          {itemList.map((item, index) => (
  <Tr key={index}>
    <Td>{index + 1}</Td>
    <Td>
      {editingIndex === index ? (
        <Input
          name="itemName"
          value={editingItem.itemName}
          onChange={handleEditChange}
          bg="white"
          width="110%"
        />
      ) : (
        item.itemName
      )}
    </Td>
    <Td>
      {editingIndex === index ? (
        <Input
          name="quantity"
          value={editingItem.quantity}
          onChange={handleEditChange}
          bg="white"
          width="80%"
        />
      ) : (
        item.quantity
      )}
    </Td>
    <Td>
      {editingIndex === index ? (
        <Input
          name="unit"
          value={editingItem.unit}
          onChange={handleEditChange}
          bg="white"
          width="150%"
        />
      ) : (
        item.unit
      )}
    </Td>
    <Td>
      {editingIndex === index ? (
        <Input
          name="mrp"
          value={editingItem.mrp}
          onChange={handleEditChange}
          bg="white"
          width="150%"
        />
      ) : (
        item.mrp
      )}
    </Td>
    <Td>
      {editingIndex === index ? (
        <Input
          name="purchaseRate"
          value={editingItem.purchaseRate}
          onChange={handleEditChange}
          bg="white"
          width="80%"
        />
      ) : (
        item.purchaseRate
      )}
    </Td>
    <Td>
      {editingIndex === index ? (
        <Input
          name="discount"
          value={editingItem.discount}
          onChange={handleEditChange}
          bg="white"
          width="80%"
        />
      ) : (
        item.discount
      )}
    </Td>
    <Td>
      {editingIndex === index ? (
        <Input
          name="sgst"
          value={editingItem.sgst}
          onChange={handleEditChange}
          bg="white"
          width="150%"
        />
      ) : (
        item.sgst
      )}
    </Td>
    <Td>
      {editingIndex === index ? (
        <Input
          name="cgst"
          value={editingItem.cgst}
          onChange={handleEditChange}
          bg="white"
          width="150%"
        />
      ) : (
        item.cgst
      )}
    </Td>
    <Td>
      {editingIndex === index ? (
        <Input
          name="amount"
          value={editingItem.amount}
          onChange={handleEditChange}
          bg="white"
          width="120%"
          readOnly
        />
      ) : (
        item.amount
      )}
    </Td>
    <Td>
      {editingIndex === index ? (
        <Button colorScheme="blue" size="sm" onClick={() => handleSave(index)}>
          Save
        </Button>
      ) : (
        <Button colorScheme="green" size="sm" onClick={() => handleEdit(index)}>
          Edit
        </Button>
      )}
    </Td>
    <Td>
      <Button colorScheme="red" size="sm" onClick={() => handleDeleteItem(index)}>
        Delete
      </Button>
    </Td>
  </Tr>
))}
          </Tbody>
        </Table>
      </TableContainer>
      <Box
            display="flex"
            gap="10px"
            bg='#f2f1b8'
            p={2}
            mt={5}
            alignItems="center"
            justifyContent="space-evenly"
          >
            <Box
              width="20%"
              display="flex"
              flexDirection='column'
              alignItems="left"
             bg='#e0ded7'
              border='1px solid skyblue'
              borderRadius="5px"
              p={2}
            >
              <FormLabel fontSize="xs">Cgst Total</FormLabel>
              <Input
                size="xs"
                w="100%"
                bg="#FAFAFA "
                placeholder="Cgst Total"
                name="cgstTotal"
                value={formData.cgstTotal}
                onFocus={totalFocus}
               onChange={handleInputChange}
             / >
                
            </Box>
      
            <Box
              width="20%"
              display="flex"
              flexDirection='column'
              alignItems="left"
              boxShadow="xl"
              bg='#e0ded7'
               border='1px solid skyblue'
              borderRadius="5px"
              p={2}
            >
              <FormLabel fontSize="xs">Sgst Total</FormLabel>
              <Input
              
                size="xs"
                w="100%"
                bg="#FAFAFA "
                placeholder="Sgst Total"
                 
                name="sgstTotal"
                value={formData.sgstTotal}
                onChange={handleInputChange}
              />
            </Box>
            <Box
              width="20%"
              display="flex"
              flexDirection='column'
              bg='#e0ded7'
              alignItems="left"
              boxShadow="xl"
               border='1px solid skyblue'
              borderRadius="5px"
              p={2}
            >
              <FormLabel fontSize="xs">Gross Amount</FormLabel>
              <Input
              
                size="xs"
                w="100%"
                bg="#FAFAFA "
                placeholder="Gross Amount"
                name="grossAmount"
                value={formData.grossAmount}
                onChange={handleInputChange}
              />
            </Box>
            <Box
              width="20%"
              display="flex"
              flexDirection='column'
              alignItems="left"
              bg='#e0ded7'
               border='1px solid skyblue'
              boxShadow="xl"
              borderRadius="5px"
              p={2

              }
            >
                
              <FormLabel fontSize="xs">Discount</FormLabel>
              <Flex>
              <Input
                size="xs"
                w="100%"
                bg="#FAFAFA "
                placeholder="Item Discount"
                name="itemDiscount"
                value={formData.itemDiscount}
                onChange={handleInputChange}
              />
              </Flex>
                
            </Box>
            <Box
              width="20%"
              display="flex"
               flexDirection='column'
              alignItems="left"
              bg='#e0ded7'

              boxShadow="xl"
               border='1px solid skyblue'
              borderRadius="5px"
              p={2}
            >
              <FormLabel fontSize="xs">Total Tax</FormLabel>
              <Input
                size="xs"
                w="100%"
                bg="#FAFAFA "
                placeholder="Total Tax"
                name="totalTax"
                value={formData.totalTax}
                onChange={handleInputChange}
              />
                 
            </Box>
            <Box
              width="20%"
              display="flex"
              flexDirection='column'
              alignItems="left"
              boxShadow="xl"
              bg='#e0ded7'
               border='1px solid skyblue'
              borderRadius="5px"
              p={2}
            >
              <FormLabel fontSize="xs">Discount %</FormLabel>
              <Input
              
                size="xs"
                w="100%"
                bg="#FAFAFA "
                placeholder="Discount %"
                name="cashDiscountPercentage"
                value={formData.cashDiscountPercentage}
                onChange={handleInputChange}
              />
            </Box>
            <Box
              width="20%"
              display="flex"
              flexDirection='column'
              alignItems="left"
              bg='#e0ded7'
              boxShadow="xl"
               border='1px solid skyblue'
              borderRadius="5px"
              p={2}
            >
              <FormLabel fontSize="xs">Cash Discount</FormLabel>
              <Input
                size="xs"
                w="100%"
                bg="#FAFAFA "
                placeholder="Cash Discount"
                name="cashDiscountAmount"
                value={formData.cashDiscountAmount}
                onChange={handleInputChange}
              />
                
            </Box>
            <Box
              width="20%"
              display="flex"
               flexDirection='column'
              alignItems="left"
              bg='#e0ded7'
              boxShadow="xl"
               border='1px solid skyblue'
              borderRadius="5px"
              p={2}
            >
              <FormLabel fontSize="xs">Adjustment</FormLabel>
              <Input
                size="xs"
                w="100%"
                bg="#FAFAFA "
                placeholder="Adjustment"
                name="adjustment"
                value={formData.adjustment}
                onChange={handleInputChange}
              />
                 
            </Box>
            <Box
              width="20%"
              display="flex"
              flexDirection='column'
              bg='#e0ded7'
              alignItems="left"
              boxShadow="xl"
               border='1px solid skyblue'
              borderRadius="5px"
              p={2}
            >
              <FormLabel fontSize="xs">Net Amount</FormLabel>
              <Input
              
                size="xs"
                w="100%"
                bg="#FAFAFA "
                placeholder="Net Amount"
                name="netAmount"
                value={formData.netAmount}
                onFocus={OnNetAmountFocus}
                onChange={handleInputChange}
              />
            </Box>
           
          </Box>
          <Button colorScheme='blue' onClick={save}>Save</Button>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Confirm Deletion
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure you want to delete this item?
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                Cancel
              </Button>
              <Button colorScheme="red" onClick={handleDelete} ml={3}>
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Box>
  );
};

export default PurchaseRegister;
