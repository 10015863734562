import {
    FormControl,
    FormLabel,
    FormErrorMessage,
    FormHelperText,
    Input,
    Select,
    Box,
    Flex,Image,
    Text,Button,Card,CardBody,SimpleGrid,
    Textarea,Checkbox, CheckboxGroup,useDisclosure
  } from "@chakra-ui/react";
  import { toast } from "react-hot-toast";
  import { useSelector } from "react-redux";
 
  import axios from 'axios'
  import "react-calendar/dist/Calendar.css";
  import Calendar from "react-calendar";
  import React, { useState,useEffect } from "react";
import { FaPlus } from "react-icons/fa";
import FoodCategoryModal from "./categoryRegisterModal";

  
 
  
  const FoodRegister = () => {
    // const [data1, setData1] = useState(new Date());
    // console.log(data1.toDateString());
    // const [data2, setData2] = useState(new Date());
    // console.log(data2.toDateString());
    // const [total, setTotal] = useState(0);
    // const [advance, setAdvance] = useState(0);
    //let dueAmount = 0;
    const [dueAmount, setDueAmount] = useState(0); 
    const [selectedFile, setSelectedFile] = useState(null);
  const[imageURL,setImageURL]=useState(null);
  const[foodCategory,setFoodCategory]=useState([]);
  const [uploading, setUploading] = useState(false);
  const [images,setImages]=useState([])
 const customerId=localStorage.getItem('customerId')
 const {onOpen,isOpen,onClose}=useDisclosure({})
    const handlePost = async () => {
      try {
        setUploading(true);
        const formData = new FormData();
        formData.append('customerId', customerId);
        formData.append('image', selectedFile);  // Ensure the field name is 'image' here
    
        const response = await axios.post("https://api.rcb.tdrsoftware.in/images", formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
        const data = response.data;
        setImageURL(Object.values(data).join());
     toast.success("Image uploaded successfully");
        console.log(data,'data')
      } catch (err) {
        toast.error(err);
      } finally {
        setUploading(false);
      }
      
    };
    console.log(imageURL,'imageURL');
    const handleToggle=()=>{
        onOpen()
    }
    const fetchCategory=async()=>{
       try {
        const response=await axios.get(`https://api.rcb.tdrsoftware.in/api/getFoodCategory/${customerId}`);
        //  toast.success(response.data.message)
        setFoodCategory(response.data.foodCategory)
       } catch (error) {
        toast.error(error.message)
       }
    }
    useEffect(()=>{
        fetchCategory()
    },[customerId])
    
    const [formData, setFormData] = useState({
      name: "",
      description: "",
      category:"",
     
      acRate: 0,
      nonAcRate:0,
      sgst: 0,
      cgst: 0,
      nonAcSgst:0,
      nonAcCgst:0,
      acHalfRate:0,
      acHalfSgst:0,
      acHalfCgst:0,
      nonAcHalfRate:0,
      nonAcHalfCgst:0,
      nonAcHalfSgst:0,


     

      image: "",
      type:'',code:'',
      halfPlate:false
      
      
    });
    const handleFileChange = (event) => {
      setSelectedFile(event.target.files[0]);
     
      
    }
    const handleCheckboxChange = (e) => {
        const { name, checked } = e.target;
        setFormData((prevFormData) => ({
          ...prevFormData,
          [name]: checked, // Set formData.halfPlate to true/false based on checkbox state
        }));
      };
      

    const validateForm = () => {
      const requiredFields = [
        "name",
        "description",
        "category",
       
        "acRate",
        
      
       


          
       
        
        "type",
        "code"
        
      ];
  
      for (const field of requiredFields) {
        if (!formData[field]) {
          return false;
        }
      }
      return true;
    };
    
  
    const handleInputChange = (e) => {
      const { name, value } = e.target;
      setFormData({
        ...formData,
        [name]: value,
      });
    };
  
    const handleSubmit = async(e) => {
      if (!validateForm()) {
        toast.error("Please fill in all required fields.");
        return;
      }
      e.preventDefault();
      // Here you can send formData to your backend server or process it further
      console.log("Form submitted with data:", formData);
     
      // Reset form after submission
      setFormData({
        name: formData.name,
      description: formData.description,
      category:formData.category,
     
      acRate: formData.acRate,
      nonAcRate: formData.nonAcRate,
      sgst: formData.acRate*0.025,
      cgst: formData.acRate*0.025,
      nonAcCgst:formData.nonAcRate*0.025,
      nonAcSgst:formData.nonAcRate*0.025,
      acHalfRate:formData.acHalfRate,
      acHalfCgst:formData.acHalfRate*0.025,
      acHalfSgst:formData.acHalfRate*0.025,
      nonAcHalfRate:formData.nonAcHalfRate,
      nonAcHalfCgst:formData.nonAcHalfRate*0.025,
      nonAcHalfSgst:formData.nonAcHalfRate*0.025,

      image: imageURL,
      
      type:formData.type,
      code:formData.code,
      halfPlate:formData.halfPlate
      
       
      });
    
      
    };
    const save=async(e)=>{
      if (!validateForm()) {
        toast.error("Please fill in all required fields.");
        return;
      }
      try{
        const response=await axios.post("https://api.rcb.tdrsoftware.in/api/foodRegister",{
          customerId:customerId,data:formData
        });
        console.log('Data Submitted: ',response.data);
        toast.success(response.data.message)
        setFormData({
          name: "",
        description: "",
        category:"",
       
        acRate: "",
        nonAcRate:0,
        sgst: 0,
        cgst: 0,
        nonAcCgst:0,
        nonAcCgst:0,
        image:"",
        
        type:"",
        code:'',
        acHalfRate:0,
        acHalfSgst:0,
        acHalfCgst:0,
        nonAcHalfRate:0,
        nonAcHalfCgst:0,
        nonAcHalfSgst:0,
        halfPlate:false
        
        
         
        });
      }catch(error){
        toast.error(error)
      }
    }
    
    return (
      <Box   bg='#f7f7f7 '
      columns={{ sm: 2, md: 4 }}
      spacing='8'
      p='10'
      textAlign='center'
      
      color='black' width='full' height='fit-content' overflowX='auto'  >
      
        <FormControl isRequired onSubmit={handleSubmit}>
          <Box display="grid"  gridGap={1} >
            <Flex
              display="flex"
              gap="10px"
              alignItems="center"
              justifyContent="space-evenly"
             
            >
              <Box width="40%"  display="flex" alignItems="center"  boxShadow='xl'
              borderRadius="5px"
              
              p={4}>
                <FormLabel  >Food Name</FormLabel>
                <Input w='70%' bg='#FAFAFA ' placeholder="Enter food Item"  name='name' value={formData.name} onChange={handleInputChange} />
              </Box>
              <Box width="40%" display="flex" alignItems="center"  boxShadow='xl'
              borderRadius="5px"
              p={4}>
                <FormLabel>Description</FormLabel>
                <Textarea w='70%' bg='#FAFAFA ' type="text" placeholder="Enter Description" name='description' value={formData.description} onChange={handleInputChange} />
              </Box>
            </Flex>
            <Box
              display="flex"
              gap="10px"
              alignItems="center"
              justifyContent="space-evenly"
              
            >
              <Box width="30%" display="flex" alignItems="center"  boxShadow='xl'
              borderRadius="5px"
              p={4}>
                <FormLabel>Category</FormLabel>
                <Select w='66%' bg='#FAFAFA ' placeholder="Enter Category" name='category' value={formData.category} onChange={handleInputChange}>
                    {foodCategory.map((item,index)=>(
                        <option key={index} value={item.name}>{item.name}</option>
                    ))}
                </Select>
                <Button ml={2} size='sm' colorScheme="blue" onClick={handleToggle}><FaPlus/></Button>
                 
                 
                
              </Box>
              <Box width="30%" display="flex" alignItems="center"  boxShadow='xl'
              borderRadius="5px"
              p={4}>
                <FormLabel>Type</FormLabel>
                <Select w='70%'  bg='#FAFAFA ' placeholder="Enter Type" name='type' value={formData.type} onChange={handleInputChange} >
                  
                  <option>Veg</option>
                  <option>Non-Veg</option>
                </Select>
              </Box>
              <Box width="30%" display="flex" alignItems="center"  boxShadow='xl'
              borderRadius="5px"
              p={4}>
                <FormLabel>Code</FormLabel>
                <Input w='70%'  bg='#FAFAFA ' placeholder="Enter Code" name='code' value={formData.code} onChange={handleInputChange} />
                  
                  
              </Box>
             
            </Box>
            <Box
              display="flex"
              gap="10px"
              alignItems="center"
              justifyContent="space-evenly"
              
            >
              <Box width="30%" display="flex" alignItems="center"  boxShadow='xl'
              borderRadius="5px"
              p={4}>
                <FormLabel>Rate</FormLabel>
                <Input w='80%'  bg='#FAFAFA ' placeholder="Enter Food acRate" name='acRate' value={formData.acRate} onChange={handleInputChange}/>
              </Box>
              <Box width="30%" display="flex" alignItems="center"  boxShadow='xl'
              borderRadius="5px"
              p={4}>
                <FormLabel>SGST</FormLabel>
                <Input w='80%' bg='#FAFAFA ' placeholder="Enter SGST" name='sgst' value={formData.sgst} onChange={handleInputChange} />
              </Box>
              <Box width="30%" display="flex" alignItems="center"  boxShadow='xl'
              borderRadius="5px"
              p={4}>
                <FormLabel>CGST</FormLabel>
                <Input w='80%' bg='#FAFAFA ' placeholder="Enter SGST" name='cgst' value={formData.cgst} onChange={handleInputChange} />
              </Box>
            </Box>
            <Box
              display="flex"
              gap="10px"
              alignItems="center"
              justifyContent="space-evenly"
              
            >
              <Box width="30%" display="flex" alignItems="center"  boxShadow='xl'
              borderRadius="5px"
              p={4}>
                <FormLabel>Non-Ac Rate</FormLabel>
                <Input w='40%'  bg='#FAFAFA ' placeholder="Enter Food Non-ac Rate" name='nonAcRate' value={formData.nonAcRate} onChange={handleInputChange}/>
              </Box>
              <Box width="30%" display="flex" alignItems="center"  boxShadow='xl'
              borderRadius="5px"
              p={4}>
                <FormLabel> Non-Ac SGST</FormLabel>
                <Input w='40%' bg='#FAFAFA ' placeholder="Enter SGST" name='nonAcSgst' value={formData.nonAcSgst} onChange={handleInputChange} />
              </Box>
              <Box width="30%" display="flex" alignItems="center"  boxShadow='xl'
              borderRadius="5px"
              p={4}>
                <FormLabel> Non-Ac CGST</FormLabel>
                <Input w='40%' bg='#FAFAFA ' placeholder="Enter SGST" name='nonAcSgst' value={formData.nonAcCgst} onChange={handleInputChange} />
              </Box>
            </Box>
            {formData.halfPlate ? 
            <Box
              display="flex"
              gap="10px"
              alignItems="center"
              justifyContent="space-evenly"
              
            >
              <Box width="30%" display="flex" alignItems="center"  boxShadow='xl'
              borderRadius="5px"
              p={4}>
                <FormLabel>Ac Half Rate</FormLabel>
                <Input w='40%'  bg='#FAFAFA ' placeholder="Enter Food ac half Rate" name='acHalfRate' value={formData.acHalfRate} onChange={handleInputChange}/>
              </Box>
              <Box width="30%" display="flex" alignItems="center"  boxShadow='xl'
              borderRadius="5px"
              p={4}>
                <FormLabel>Ac half SGST</FormLabel>
                <Input w='40%' bg='#FAFAFA ' placeholder="Enter SGST" name='acHalfSgst' value={formData.acHalfSgst} onChange={handleInputChange} />
              </Box>
              <Box width="30%" display="flex" alignItems="center"  boxShadow='xl'
              borderRadius="5px"
              p={4}>
                <FormLabel>Ac half CGST</FormLabel>
                <Input w='40%' bg='#FAFAFA ' placeholder="Enter SGST" name='acHalfCgst' value={formData.acHalfCgst} onChange={handleInputChange} />
              </Box>
            </Box> : ""}
            {formData.halfPlate ? 
            <Box
              display="flex"
              gap="10px"
              alignItems="center"
              justifyContent="space-evenly"
              
            >
              <Box width="30%" display="flex" alignItems="center"  boxShadow='xl'
              borderRadius="5px"
              p={4}>
                <FormLabel>Non-Ac Half Rate</FormLabel>
                <Input w='40%'  bg='#FAFAFA ' placeholder="Enter Food ac half Rate" name='nonAcHalfRate' value={formData.nonAcHalfRate} onChange={handleInputChange}/>
              </Box>
              <Box width="30%" display="flex" alignItems="center"  boxShadow='xl'
              borderRadius="5px"
              p={4}>
                <FormLabel>Non-Ac half SGST</FormLabel>
                <Input w='40%' bg='#FAFAFA ' placeholder="Enter SGST" name='acHalfSgst' value={formData.nonAcHalfSgst} onChange={handleInputChange} />
              </Box>
              <Box width="30%" display="flex" alignItems="center"  boxShadow='xl'
              borderRadius="5px"
              p={4}>
                <FormLabel>Non-Ac half CGST</FormLabel>
                <Input w='40%' bg='#FAFAFA ' placeholder="Enter SGST" name='nonAcHalfCgst' value={formData.nonAcHalfCgst} onChange={handleInputChange} />
              </Box>
            </Box> : ""}
            <Box
              display="flex"
              gap="10px"
              alignItems="center"
              justifyContent="space-evenly"
              mt={3}
              
            >
             <Box width="40%" display="flex" alignItems="center"  boxShadow='xl'
              borderRadius="5px"
              p={4}>
                
                <Checkbox w='40%' bg='#fafafa' value={formData.halfPlate} name='halfPlate' onChange={handleCheckboxChange}>Half Available ?</Checkbox>
              </Box>
             
              <Box width="40%" display="flex" alignItems="center"  boxShadow='xl'
              borderRadius="5px"
              p={4}>
                <FormLabel>Image</FormLabel>
                <Input w='70%' type="file" bg='#FAFAFA ' onChange={handleFileChange}  />
                <Button variant='solid' onClick={handlePost} disabled={uploading}>
        {uploading ? "Uploading..." : "Upload"}
      </Button>
                
              </Box>
             
            </Box>
            
            
           
             
             
           
            
           
            
            
          <hr/>
          

          </Box>
         
          <Button type="submit" variant='solid' mr={4} color='white' bg='tomato' _hover={{color:'tomato',bg:"white"}} mt={5} onClick={handleSubmit}>Generate Gst</Button>
          <Button type="submit" variant='solid' color='white' bg='tomato' _hover={{color:'tomato',bg:"white"}} mt={5} onClick={save}>Save</Button>
        </FormControl>
        <FoodCategoryModal isOpen={isOpen} onOpen={onOpen} onClose={onClose} customerId={customerId}/>
        
      </Box>
    );
  };
  
  export default FoodRegister;
  