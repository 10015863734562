import React, { createContext, useState, useContext, useEffect } from 'react';
import axios from 'axios';

const KotContext = createContext();
export const useKotContext = () => useContext(KotContext);
const customerId = localStorage.getItem('customerId');

export const KotProvider = ({ children }) => {
  const [kotNumber, setKotNo] = useState('');

  // Function to fetch kotNumber from the API
  const fetchKotNo = async (customerId, tableNo) => {
    try {
      const response = await axios.get(`https://api.rcb.tdrsoftware.in/api/getKotByTable/${customerId}/${tableNo}`); // Use backticks for template literals
      console.log(response,'response')
      setKotNo(response.data.kot.kotNo);
    } catch (error) {
      console.error('Error fetching kot number:', error);
    }
  };

  // Function to update kotNumber via API
  const updateKotNo = async (customerId, newKotNo,tableNo) => {
    try {
      const response = await axios.post(`https://api.rcb.tdrsoftware.in/api/kotNumberRegister/${customerId}`, { data: {kotNo:newKotNo,tableNo:tableNo }});
      setKotNo(response.data.kot);
    } catch (error) {
      console.error('Error updating kot number:', error);
    }
  };

  // Function to clear kotNumber (can also use API if needed)
  const clearKotNo = async (customerId, tableNo) => {
    try {
      await axios.delete(`https://api.rcb.tdrsoftware.in/api/deleteKotByTable/${customerId}/${tableNo}`); // Use backticks for template literals
      setKotNo(''); // Optionally clear the kot number via API
    } catch (error) {
      console.error('Error clearing kot number:', error);
    }
  };

  return (
    <KotContext.Provider value={{ kotNumber, fetchKotNo, updateKotNo, clearKotNo }}>
      {children}
    </KotContext.Provider>
  );
};
