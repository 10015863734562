// Dashboard.js
import React,{useState,useEffect} from 'react';
import {
  Box,
  Grid,
  GridItem,
  Heading,
  Flex,
  Text,
  Stat,
  StatLabel,Button,
  StatNumber,Table,Tbody,Thead,Tr,Td,Select,Input
} from '@chakra-ui/react';
import Chart from './Chart';
import toast from 'react-hot-toast';
import Donut from './dountChart';

import axios from 'axios';
import { FaRupeeSign } from 'react-icons/fa';

const Dashboard = () => {
    const customerId=localStorage.getItem("customerId")
    const [data,seTdata]=useState([])
    
    const [foodBill,setFoodBill]=useState([]);
    const [cash,setCash]=useState([]);
    const [online,setOnline]=useState([]);
    const [copyButtonText, setCopyButtonText] = useState('Copy'); 
    const [filteredData, setFilteredData] = useState([]);
    const [copiedRegistration, setCopiedRegistration] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
    const [selectedDate, setSelectedDate] = useState(new Date().toISOString().split('T')[0]); // State for selected date

   
    const fetchData=async()=>{
         try {
          const respose=await axios.get(`https://api.rcb.tdrsoftware.in/api/getQuickBillTodayData/${customerId}`);
         setFoodBill(respose.data.totalSales);
         } catch (error) {
         // toast.error(error.message);
         }

    }
    const cashData=async()=>{
      try {
       const respose=await axios.get(`https://api.rcb.tdrsoftware.in/api/getTodayCashData/${customerId}`);
      setCash(respose.data.totalSales);
      } catch (error) {
       //toast.error(error.message);
      }

 }
 const onlineData=async()=>{
  try {
   const respose=await axios.get(`https://api.rcb.tdrsoftware.in/api/getTodayOnlineData/${customerId}`);
  setOnline(respose.data.totalSales);
  } catch (error) {
   //toast.error(error.message);
  }

}

  
   
    useEffect(()=>{
        
       fetchData();
       cashData();
       onlineData();
    },[customerId])
   
  return (
    <Box p="6" bg='
#e6cca3' height='100vh'
>
      <Text as='b' fontSize='2xl' mb={20}>Admin Dashboard</Text>
      <Box display='flex' flexDirection='row' justifyContent='space-around' alignItems='center' mt={10}>
        <Box display='flex' flexDirection='column' justifyContent='center' alignItems='center' bg='#0F172B' borderRadius='12px' p={4} >
          <Text  fontSize='2xl' color='white'>Today's Sales </Text>
          <Text display='flex' alignItems='center' color='#FEA116' as='b' fontSize='xl'><FaRupeeSign/> {foodBill} </Text>
        </Box>
        <Box display='flex' flexDirection='column' justifyContent='center' alignItems='center' bg='#0F172B'  borderRadius='12px' p={4}>
          <Text  fontSize='2xl' color='white'>Today's Cash  </Text>
          <Text display='flex' alignItems='center' color='#FEA116' as='b' fontSize='xl'><FaRupeeSign/> {cash} </Text>
        </Box>
        <Box display='flex' flexDirection='column' justifyContent='center' alignItems='center' bg='#0F172B'  borderRadius='12px' p={4}>
          <Text  fontSize='2xl' color='white'>Today's Online </Text>
          <Text display='flex' alignItems='center' color='#FEA116' as='b' fontSize='xl'><FaRupeeSign/> {online} </Text>
        </Box>
      </Box>
      
      <Grid templateColumns="repeat(2, 1fr)" gap={8} mt={10}>
     
        <GridItem bg='#EAE5DF ' p={5} borderRadius={6} overflowX='auto'>
          <Chart />
          <Flex align="center" justify="center" >
            <Stat>
              <StatLabel>FoodBills</StatLabel>
              {/* <StatNumber>245</StatNumber> */}
            </Stat>
          </Flex>
        </GridItem>
        <GridItem  bg='#EAE5DF ' p={2} borderRadius={6}>
          <Donut mt={5}/>
          <Flex align="center" justify="center" >
            <Stat>
              <StatLabel>Total Bills</StatLabel>
              {/* <StatNumber>245</StatNumber> */}
            </Stat>
          </Flex>
        </GridItem>
      </Grid>
    </Box>
  );
};

export default Dashboard;
