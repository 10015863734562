import axios from 'axios';
import toast from 'react-hot-toast';
import { useKotContext } from '../context/kotContext';

const UpdateKotPrint = (arr1, tableNo, totalAmount, printerName) => {
  const { kotNumber } = useKotContext();

  const customerId = localStorage.getItem('customerId');

  // Function to update and print KOT
  const printKot = async () => {
    try {
      const response = await axios.patch('https://api.rcb.tdrsoftware.in/api/kotBillUpdate', {
        customerId: customerId,
        kotNo: kotNumber,
        data: {
          foodItem: arr1,
        },
      });

      toast.success(response.data.message);

      const data = response.data.updatedFoodItems || []; // Ensure updatedFoodItems is an array

      // Prepare print data with CSS for 3-inch page size
      const printData = `
  <style>
    @page {
      size: 3in auto; /* Set page width to 3 inches */
      margin: 0; /* Remove margins for accurate sizing */
    }
    body {
      width: 3in; /* Page width of 3 inches */
      margin: 0;
      font-size: 12px;
    }
    h2 {
      font-size: 14px;
      margin-bottom: 10px;
    }
    p {
      margin: 5px 0;
      font-size: 12px;
    }
    table {
      width: 100%;
      border-collapse: collapse;
      font-size: 12px;
    }
    td {
      padding: 5px;
      border: 1px solid #000;
    }
    /* Make the name column larger and quantity smaller */
    td.name {
      width: 70%; /* Adjust this width as needed */
    }
    td.quantity {
      width: 30%; /* Adjust this width as needed */
    }
  </style>
  <body>
    <h2>KOT Details:</h2>
    <p>Table No.: ${tableNo}</p>
    <p>KOT No.: ${kotNumber}</p>
    <p>Food Items:</p>
    <table>
      <thead>
        <tr>
          <td class="name">Name</td>
          <td class="quantity">Quantity</td>
        </tr>
      </thead>
      <tbody>
        ${Array.isArray(data) ? data.map((item) => `
          <tr key="${item._id}">
            <td class="name">${item.name}</td>
            <td class="quantity">${item.newQuantity - item.oldQuantity}</td>
          </tr>
        `).join('') : ''}
      </tbody>
    </table>
  </body>
`;


      // Create a hidden iframe for printing
      const iframe = document.createElement('iframe');
      iframe.style.position = 'fixed';
      iframe.style.top = '-9999px'; // Hide the iframe offscreen
      document.body.appendChild(iframe);

      // Write content to iframe
      const iframeDoc = iframe.contentWindow.document;
      iframeDoc.open();
      iframeDoc.write(printData);
      iframeDoc.close();

      // Trigger print dialog
      iframe.contentWindow.focus();
      iframe.contentWindow.print();

      // Remove iframe after printing
      setTimeout(() => {
        document.body.removeChild(iframe);
      }, 1000);

    } catch (error) {
      console.error('Error saving bill:', error);
      toast.error('Error in Kot');
    }
  };

  return printKot;
  // Return the function to be used in a button click
};

export default UpdateKotPrint;
