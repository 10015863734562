import {
    FormControl,
    FormLabel,
    Input,
    Button,
    Box,
    Text,
    TableContainer,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    TableCaption,
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    useToast,
    Select
} from "@chakra-ui/react";
import { useRef, useState, useEffect } from "react";
import { useReactToPrint } from "react-to-print";
import axios from 'axios';
import toast from "react-hot-toast";

const ItemRegister = () => {
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });
    const customerId = localStorage.getItem('customerId');
    const [data, setData] = useState([]);
    const [isEditing, setIsEditing] = useState(false);
    const [editItemId, setEditItemId] = useState(null);
    const [isOpen, setIsOpen] = useState(false);
    const [deleteItemId, setDeleteItemId] = useState(null);
    const cancelRef = useRef();

    const [formData, setFormData] = useState({
        name: "",
        companyName: "",
        groupName: "",
        purchaseUnit: "",
        usageUnit: "",
        equivalentNo: "",
        sgst: null,
        cgst: null,
        hsnNo: null
    });

    const fetchData = async () => {
        try {
            const response = await axios.get(`https://api.rcb.tdrsoftware.in/api/item/${customerId}`);
            setData(response.data.item);
        } catch (error) {
            toast.error(error.message);
        }
    };

    const edit = (item) => {
        setIsEditing(true);
        setEditItemId(item._id);
        setFormData({
            name: item.name,
            companyName: item.companyName,
            groupName: item.groupName,
            purchaseUnit: item.purchaseUnit,
            usageUnit: item.usageUnit,
            equivalentNo: item.equivalentNo,
            sgst: item.sgst,
            cgst: item.cgst,
            hsnNo: item.hsnNo,
        });
       // console.log(item);
    };
    const handleFocus = () => {
        let equivalentNo = "";
        if (formData.purchaseUnit === 'kg' && formData.usageUnit === 'gram') {
            equivalentNo = "1kg = 1000 gram";
        } else if (formData.purchaseUnit === 'litre' && formData.usageUnit === 'ml') {
            equivalentNo = "1litre = 1000 ml";
        } else if (formData.purchaseUnit === 'dozen' && formData.usageUnit === 'pc') {
            equivalentNo = "1dozen = 12 pcs";
        }else{
            equivalentNo = `1${formData.purchaseUnit} = 1${formData.usageUnit} `
        }
        setFormData((prevFormData) => ({
            ...prevFormData,
            equivalentNo: equivalentNo
        }));
    };

    useEffect(() => {
        fetchData();
    }, [customerId]);
    

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const validateForm = () => {
        const requiredFields = [
            "name", "companyName", "groupName", "purchaseUnit",
            "usageUnit", "equivalentNo",  "hsnNo"
        ];
        for (const field of requiredFields) {
            if (!formData[field]) {
                return false;
            }
        }
        return true;
    };

    const save = async (e) => {
        console.log(formData,'formData')
        e.preventDefault();
        if (!validateForm()) {
            toast.error("Please fill in all required fields.");
            return;
        }
        

        try {
            if (isEditing) {
                const response = await axios.put(`https://api.rcb.tdrsoftware.in/api/itemUpdate/${customerId}/${editItemId}`,{data: formData});
                toast.success(response.data.message);
                setIsEditing(false);
                setEditItemId(null);
            } else {
                const response = await axios.post("https://api.rcb.tdrsoftware.in/api/itemRegister", {
                    customerId:customerId, data:formData
                });
                toast.success(response.data.message);
            }

            setFormData({
                name: "",
                companyName: "",
                groupName: "",
                purchaseUnit: "",
                usageUnit: "",
                equivalentNo: "",
                sgst: "",
                cgst: "",
                hsnNo: ""
            });
            fetchData();
        } catch (error) {
            toast.error(error.message);
        }
    };
    const handleDelete=async()=>{
        try{
            const response=await axios.delete(`https://api.rcb.tdrsoftware.in/api/itemDelete/${customerId}/${deleteItemId}`);
            toast.success(response.data.message);
            setIsOpen(false)
            fetchData();
        }catch(error){
            toast.error(error.message);
        }
    }
    const openModal = (id) => {
        setDeleteItemId(id);
        setIsOpen(true);
      };
    
      const onClose = () => {
        setIsOpen(false);
        setDeleteItemId(null);
      };
     
    
    return (
        <Box bg='#EAE5DF' p='10' textAlign='center' color='black' width='100%' height='fit-content'>
            <Text fontSize='2xl' fontWeight='bold' textAlign='center' color='black' mb={10}>Item Register</Text>

            <FormControl isRequired onSubmit={save} fontSize='sm'>
                <Box display="grid" gridGap={1}>
                    {/* Form Fields */}
                    <Box display="flex" gap="10px" alignItems="center" justifyContent="space-evenly">
                        <Box width="40%" display="flex" alignItems="center" boxShadow='xl' borderRadius="5px" p={4}>
                            <FormLabel fontSize='sm'>Name</FormLabel>
                            <Input size='sm' w='78%' bg='#FAFAFA ' placeholder="Enter Item Name" name='name' value={formData.name} onChange={handleInputChange} />
                        </Box>
                        <Box width="40%" display="flex" alignItems="center" boxShadow='xl' borderRadius="5px" p={4}>
                            <FormLabel fontSize='sm'>Company Name</FormLabel>
                            <Input size='sm' w='65%' bg='#FAFAFA ' placeholder="Enter Company Name" name='companyName' value={formData.companyName} onChange={handleInputChange} />
                        </Box>
                    </Box>
                    <Box display="flex" gap="10px" alignItems="center" justifyContent="space-evenly">
                        <Box width="40%" display="flex" alignItems="center" boxShadow='xl' borderRadius="5px" p={4}>
                            <FormLabel fontSize='sm'>Group Name</FormLabel>
                            <Input size='sm' w='70%' bg='#FAFAFA ' placeholder="Enter Group Name" name='groupName' value={formData.groupName} onChange={handleInputChange} />
                        </Box>
                        <Box width="40%" display="flex" alignItems="center" boxShadow='xl' borderRadius="5px" p={4}>
                            <FormLabel fontSize='sm'>Purchase Unit</FormLabel>
                            <Input size='sm' w='65%' bg='#FAFAFA ' placeholder="Enter Purchase Unit" name='purchaseUnit' value={formData.purchaseUnit} onChange={handleInputChange} />
                                
                                
                           
                        </Box>
                    </Box>
                    <Box display="flex" gap="10px" alignItems="center" justifyContent="space-evenly">
                        <Box width="40%" display="flex" alignItems="center" boxShadow='xl' borderRadius="5px" p={4}>
                            <FormLabel fontSize='sm'>Usage Unit</FormLabel>
                            <Input size='sm' w='76%' bg='#FAFAFA ' placeholder="Enter Usage Unit" name='usageUnit' value={formData.usageUnit} onChange={handleInputChange} />
                               
                                
                               
                        </Box>
                        <Box width="40%" display="flex" alignItems="center" boxShadow='xl' borderRadius="5px" p={4}>
                            <FormLabel fontSize='sm'>Equivalent No</FormLabel>
                            <Input size='sm' w='65%' bg='#FAFAFA ' placeholder="Enter Equivalent No" name='equivalentNo' value={formData.equivalentNo} onChange={handleInputChange} onFocus={handleFocus} />
                        </Box>
                    </Box>
                    <Box display="flex" gap="10px" alignItems="center" justifyContent="space-evenly">
                        <Box width="30%" display="flex" alignItems="center" boxShadow='xl' borderRadius="5px" p={4}>
                            <FormLabel fontSize='sm'>Cgst</FormLabel>
                            <Input size='sm' w='76%' bg='#FAFAFA ' placeholder="Enter Cgst" name='cgst' value={formData.cgst} onChange={handleInputChange} />
                        </Box>
                        <Box width="30%" display="flex" alignItems="center" boxShadow='xl' borderRadius="5px" p={4}>
                            <FormLabel fontSize='sm'>Sgst</FormLabel>
                            <Input size='sm' w='76%' bg='#FAFAFA ' placeholder="Enter Sgst" name='sgst' value={formData.sgst} onChange={handleInputChange} />
                        </Box>
                        <Box width="30%" display="flex" alignItems="center" boxShadow='xl' borderRadius="5px" p={4}>
                            <FormLabel fontSize='sm'>HSN No</FormLabel>
                            <Input size='sm' w='65%' bg='#FAFAFA ' placeholder="Enter HSN No" name='hsnNo' value={formData.hsnNo} onChange={handleInputChange} />
                        </Box>
                    </Box>
                </Box>

                <Button type="submit" variant='solid' color='#0F172B' bg='#FEA116' _hover={{ color: 'white' }} mt={5} onClick={save}>
                    {isEditing ? "Update" : "Save"}
                </Button>
            </FormControl>

            <TableContainer width='full' mt={10} border='1px solid black' p={4} bg='white' borderRadius='10px' fontSize='sm'>
                <Table size='sm' variant='striped' colorScheme='teal'>
                    <TableCaption>Item Data</TableCaption>
                    <Thead>
                        <Tr >
                            <Th >Name</Th>
                            <Th>Company Name</Th>
                            <Th>Group Name</Th>
                            <Th>Purchase Unit</Th>
                            <Th>Usage Unit</Th>
                            <Th>Equivalent No</Th>
                            <Th>Cgst</Th>
                            <Th>Sgst</Th>
                            <Th>Hsn No</Th>
                            <Th>Edit</Th>
                            <Th>Delete</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {data.map((item, index) => (
                            <Tr key={index}>
                                <Td>{item.name}</Td>
                                <Td>{item.companyName}</Td>
                                <Td>{item.groupName}</Td>
                                <Td>{item.purchaseUnit}</Td>
                                <Td>{item.usageUnit}</Td>
                                <Td>{item.equivalentNo}</Td>
                                <Td>{item.cgst}</Td>
                                <Td>{item.sgst}</Td>
                                <Td>{item.hsnNo}</Td>
                                <Td><Button colorScheme="green" size="sm" onClick={() => edit(item)}>Edit</Button></Td>
                                <Td><Button colorScheme="red" size="sm" onClick={() => openModal(item._id)}>Delete</Button></Td>

                            </Tr>
                        ))}
                    </Tbody>
                </Table>
            </TableContainer>
            <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Confirm Deletion
            </AlertDialogHeader>

            <AlertDialogBody>Are you sure you want to delete this item?</AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                Cancel
              </Button>
              <Button colorScheme="red" onClick={handleDelete} ml={3}>
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
      
        </Box>
    );
};

export default ItemRegister;
