import './App.css';
import Layout from './layout/index.js';
import { useEffect } from 'react';
import { Toaster,toast,toastStyles } from "react-hot-toast";
import Cookies from 'js-cookie';
import Login from './modules/auth/Login/Login.js';
import ForgotPassword from './modules/auth/Forgot/forgotPassword.js';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import GenerateOtp from './modules/auth/Forgot/generateOtp.js';
import io from 'socket.io-client';
import { debounce } from 'lodash';
import FoodData102 from './modules/food/foodData102.js';
import FoodData103 from './modules/food/foodData103.js';
import FoodData104 from './modules/food/foodData104.js';

const socket = io('https://api.rcb.tdrsoftware.in');

function App() {
  //const customerId = localStorage.getItem('customerId');
  useEffect(() => {
    const customerId = localStorage.getItem('customerId'); // Assuming you store customerId in cookies
  
    // Join a room for this customerId
    socket.emit('joinRoom', customerId);
  
    // Listen for KOT generation events only for this room
    socket.on('kotGenerated', (data) => {
      toast.custom((t) => (
        <div style={{
          position: 'relative',
          padding: '16px',
          backgroundColor: '#4CAF50',
          color: '#fff',
          borderRadius: '4px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}>
          <span>{data.message}</span>
          <button
            onClick={() => toast.dismiss(t.id)}
            style={{
              background: 'none',
              border: 'none',
              color: '#fff',
              cursor: 'pointer',
              fontSize: '16px',
            }}
          >
            &#10005; {/* Close icon */}
          </button>
        </div>
      ), {
        icon: '📢',
        duration: Infinity, // Keep the toast on screen until manually dismissed
      });
     // console.log(Received KOT event: ${data.message}, 'msg');
    });
  
    return () => {
      socket.off('kotGenerated'); // Clean up the listener on component unmount
    };
  }, []);
  
  
  const token = Cookies.get('token'); // Check if token exists
  console.log(token, 'token');

  return (
    <>
      <Toaster
        toastOptions={{
          className: '',
          style: {
            padding: '10px',
            color: '#713200',
            width: "300px"
          },
        }}
      />

      {/* Define Router here at the top-level */}
      
        <Routes>
          {/* Protected Route: Only accessible when token is present */}
          {token ? (
            <Route path="/*" element={<Layout />} />
          ) : (
            <>
              {/* Public routes for Login and Forgot Password */}
              <Route path="/login" element={<Login />} />
              <Route path="/forgot-password" element={<ForgotPassword />} />
              <Route path="/generate-otp" element={<GenerateOtp/>} />
              <Route path="/foodData102" element={<FoodData102/>} />
              <Route path="/foodData103" element={<FoodData103/>} />
              <Route path="/foodData104" element={<FoodData104/>} />

              {/* Redirect any other route to /login if no token */}
              <Route path="*" element={<Navigate to="/login" />} />
            </>
          )}
        </Routes>
     
    </>
  );
}

export default App;
